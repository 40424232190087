import React from 'react';
import styled from 'styled-components';

import LargeTextWithLine from '../LargeTextWithLine';

const BookingEngine = (props) => (
  <Engine>
    <LargeTextWithLine
      width={"100px"}
      text={"Book Now"}
      marginTop={'1rem'}
    />
    <IframeContainer>
      <Iframe title="Book a room" src="https://hotelhilltone.com/bookingEngine.html" allowFullScreen></Iframe>
    </IframeContainer>
  </Engine>
);

const Iframe = styled.iframe`
  width: 100%;
  border: none;
  height: 250px;
  padding: 2rem;
  overflow: hidden;
  
  top: 2rem;
  position: absolute;
  height: 500px;
  @media screen and (max-width: 840px) {
    height: 600px;
  }
`;

const IframeContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

const Engine = styled.div`
  
position: relative;
  margin-bottom: 120px;
  @media only screen and (max-width: 840px) {
    margin-bottom: 380px;
    height:200px;
  }
`;


export default BookingEngine;