import React from 'react';

import GeneralSuite from '../../../components/GeneralSuite';

import i1 from '../../../images/Rooms/superior-room/1.jpg';
import i2 from '../../../images/Rooms/superior-room/2.jpg';
import i3 from '../../../images/Rooms/superior-room/3.jpg';
import i4 from '../../../images/Rooms/superior-room/4.jpeg';
import i5 from '../../../images/Rooms/superior-room/5.jpg';
import i6 from '../../../images/Rooms/superior-room/6.jpg';

import FancyButton from '../../../components/FancyButton';

const Superior = (props) => {
   return (
      <GeneralSuite
         Heading={"Superior Rooms"}
         Description={"Room Description"}
         width={"150px"}
         SupportImages={[i1, i2, i3, i4, i5, i6]}
         DescriptionContent={() => <React.Fragment>
            <p style={{fontSize: '1.4rem', lineHeight: '2rem'}}>
               These rooms offer you ‘close to home’ comfort, with
               contemporary ambience and economical pricing.

               A well-appointed bathroom with selected amenities ensures
               that you have a convenient and comfortable stay.
            </p>
            <div
               style={{ display: 'flex', marginTop: '2rem' }}
            >
                      <a href= "https://bookings.hotelhilltone.com/?propertyId=8826">
              <FancyButton style={{margin: '1rem 0'}} text="Book a Stay" />
              </a>
            </div>
         </React.Fragment>}
         roomType={"superior"}
         backFlag={true}
         {...props}
      />
   );
}

export default Superior;