import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import LargeTextWithLine from '../LargeTextWithLine';
import FancyButton from '../FancyButton';

const TextWithImage = (props) => {
  const { SupportImages, DescriptionContent, Heading, Color , 
    width, bottomImages, menuLink , imgLogo,imgsize,marTop} = props;

  return (
    <SuiteBody>
      <Text>
        <LargeTextWithLine text={Heading} color={Color} width={width} left={"0"} />
      </Text>
      <Container>
        <StyledDescriptionContent>{DescriptionContent()}</StyledDescriptionContent>

        <ImageContainer>
      <img src={imgLogo} style={{ "width":imgsize,"margin-left":marTop,"margin-top": '-10%' }} />
          {
            Array.isArray(SupportImages) ? (
              <Carousel
                infiniteLoop
                showThumbs={false}
                autoPlay
                dynamicHeight
                stopOnHover
                emulateTouch
                interval={5000}
                transitionTime={700}
                showStatus={false}
              >
                {
                  SupportImages.map((x,i) => (
                    <div key={"TextWithingImageCarousel" + i}>
                      <StyledImage alt="#" src={SupportImages[i]} />
                    </div>
                  ))
                }
              </Carousel>
            ) : (
              <StyledImage src={SupportImages} />
            )
          }
          { bottomImages ? bottomImages() : '' }
          {
            menuLink && (
              <MenuButtonContainer>
              <a href={menuLink} target="_blank" rel="noopener noreferrer">
                <FancyButton text="Menu" color={Color} />
              </a>
              </MenuButtonContainer>
            )
          }
        </ImageContainer>
      </Container>
    </SuiteBody>
  );
}

export default TextWithImage;

TextWithImage.propTypes = {
  SupportImages: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  Heading: PropTypes.string.isRequired,
  DescriptionContent: PropTypes.func,
  width: PropTypes.string.isRequired,
  menuLink: PropTypes.string,
}

TextWithImage.defaultProps = {
  DescriptionContent: () => <div></div>,
  menuLink: ''
}

const Container = styled.div`
  width: 100%;
  margin: 0 2rem;
  display: grid;
  font-size: 1.3rem;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
    margin: 0;
  }
`;

const StyledDescriptionContent = styled.div`
  display: flex;
  height: calc(100% - 4rem);
  flex-direction: column;
  justify-content: center;
  padding-right: 2rem;
  @media only screen and (max-width: 800px) {
    padding-right: 0;
  }
  margin: 0 1rem;
`;

const ImageContainer = styled.div`
  margin: 0 5%;
  @media only screen and (max-width: 800px) {
    margin: 0 2%;
    margin-top:5%;

  }
`;

const Text = styled.div`
  @media only screen and (max-width: 920px) {
    margin: 4rem 0;
  }
`;

const SuiteBody = styled.div`
  margin: 8vh 2vw;
  @media only screen and (max-width: 850px) {
    margin-top: 60px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
`;

const MenuButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
`;