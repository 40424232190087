import React from 'react';
import styled from 'styled-components';

import rock from '../../../images/FeaturedRooms/Rock_suite.jpeg'
import deluxe from '../../../images/Rooms/Deluxe.jpg';
import executive from '../../../images/Rooms/executive.png';
import luxury from '../../../images/Rooms/Luxury.png';
import premier from '../../../images/Rooms/premier.png';
import jacuzzi from '../../../images/Rooms/jacuzzi1.png';
import superior from '../../../images/Rooms/SUPERIOR.png';

const ContentRooms = (props) => {
  const { history } = props;
  return (
    <StyledContainer>
      <ImageContainer>
        
        <Item onClick={e => history.push("/premier")}>
          <Filter>Premier <br /> Rooms</Filter>
          <StyledImage src={premier} />
        </Item>
        
        <Item onClick={e => history.push("/luxury")}>
          <Filter>Luxury <br /> Rooms</Filter>
          <StyledImage src={luxury} />
        </Item>
        <Item onClick={e => history.push("/villa")}>
          <Filter>Luxury Room<br />&bull;Private Jacuzzi</Filter>
          <StyledImage src={jacuzzi} />
        </Item>
        <Item onClick={e => history.push("/rock")}>
          <Filter>Rock <br /> Suite</Filter>
          <StyledImage src={rock} />
        </Item>
        
      </ImageContainer>
      <Item villas={true} style={{ width: '100%', marginBottom: '2rem' ,height:'600px' }} onClick={e => history.push("/executive")}>
        <Filter>Suites</Filter>
        <StyledImage src={executive} style={{ height:'600px' }}/>
      </Item>
    </StyledContainer>
  );
}

export default ContentRooms;

const StyledContainer = styled.div`
  margin: 3rem 4rem;
  @media (max-width: 650px) {
    margin: 0 2rem;
  }
  margin-bottom: 4rem;
`;

const ImageContainer = styled.div`
  width: 100%;
  display: grid;
  padding-bottom: 4rem;
  grid-template-columns: 50% 50%;
  justify-content: space-around;
  grid-row-gap: 3rem;
  grid-column-gap: 2rem;
  @media (max-width: 900px) {
    grid-template-columns: auto;
  }
`;

const Item = styled.div`
  position: relative;
  cursor: pointer;
  &:hover {
    & > h1 {
      opacity: 1;
    }
  }
  ${({villas}) => villas && `
    min-height: 280px;
    @media (max-width: 800px) {
      & > img {
        min-height: 280px;
      }    
    }
  `}
`;

const StyledImage = styled.img`
  width: 100%;
`;

const Filter = styled.h1`
  position: absolute;
  z-index: 100;
  display: flex;
  font-size: 4rem;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(126, 118, 71, 0.7);
  @media only screen and (max-width: 1000px) {
    opacity: 1;
    font-size: 3rem;
  }
`;
