import React from 'react';

import GeneralSuite from '../../../components/GeneralSuite';

import img1 from '../../../images/Rooms/deluxeroom/1.jpg';
import img2 from '../../../images/Rooms/deluxeroom/2.jpg';
import img3 from '../../../images/Rooms/deluxeroom/3.jpg';

import FancyButton from '../../../components/FancyButton';


const Deluxe = (props) => {
  return (
    <React.Fragment>
      <GeneralSuite
        Heading={"Deluxe Rooms"}
        Description={"Room Description"}
        width={"60px"}
        SupportImages={[img1, img3, img2]}
        DescriptionContent={() => <React.Fragment>
          <div style={{fontSize: '1.4rem', lineHeight: '2rem'}}>
            A perfect blend of splendor and comfort, our Deluxe Rooms
            spread over 300 sq ft provide a soothing and warm
            ambiance to each guest with carefully chosen furnishings,
            distinctive amenities and design elements.
              </div>
          <div
            style={{ display: 'flex', marginTop: '2rem' }}
          >
                 <a href= "https://bookings.hotelhilltone.com/?propertyId=8826">
              <FancyButton style={{margin: '1rem 0'}} text="Book a Stay" />
              </a>
          </div>
        </React.Fragment>}
        backFlag={true}
        roomType={"deluxe"}
        {...props}
      />
    </React.Fragment>
   );
}

export default Deluxe;