import React from 'react';
import styled from 'styled-components';
import { ROOM_FEATRUES, BED_AND_BATH, TECHNOLOGY, SERVICES, Amenities } from '../../utils/AmenitiesAndServices';

class AmenitiesAndServices extends React.Component {
  render() {
    const { roomType } = this.props;
    return(
      <Container>
        <Heading>Amenities & Services</Heading>
        <div style={{display: 'flex', justifyContent:'center'}}>
          <Grid isService={false}>
            <div>
              <HeadingWithLine text={"Room Features"} />
              <ListItems>
                {
                  Amenities[roomType].features.map((x,i) => (
                    <Item key={"service" + roomType + i}>
                      <ul><li></li></ul><span style={{paddingLeft: '5px'}}>{ROOM_FEATRUES[x]}</span>
                    </Item>
                  ))
                }
              </ListItems>
            </div>
            <div>
              <HeadingWithLine text={"Bed & Bath"} />
              <ListItems>
                {
                  Amenities[roomType].bath.map((x,i) => (
                    <Item key={"bath" + roomType + i}>
                      <li></li><span style={{paddingLeft: '5px'}}>{BED_AND_BATH[x]}</span>
                    </Item>
                  ))
                }
              </ListItems>
            </div>
            <div>
              <HeadingWithLine text={"Technology"} />
              <ListItems>
                {
                  Amenities[roomType].tech.map((x,i) => (
                    <Item key={"tech" + roomType + i}>
                      <li></li><span style={{paddingLeft: '5px'}}>{TECHNOLOGY[x]}</span>
                    </Item>
                  ))
                }
              </ListItems>
            </div>
          </Grid>
        </div>
        <div style={{marginTop: '3rem'}}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{display: 'flex', width: '100%', maxWidth: '1000px',}}>
              <HeadingWithLine text={"Services"} />              
            </div>
          </div>
          <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
            <Grid isService={true}>
              {
                [{a: 0, b: 3}, { a: 3, b: 6 }, {a: 6, b: 9}].map(({a,b}, i) => (
                  <ListItems key={"list" + i}>
                    {
                      SERVICES.slice(a,b).map((service_text,i) => (
                        <Item
                          key={"service" + roomType + i * b}
                          style={{ width: '100%', }}
                        >
                          <li></li><span style={{paddingLeft: '5px'}}>{service_text}</span>
                        </Item>
                      ))
                    }
                  </ListItems>
                ))
              }
            </Grid>
          </div>
        </div>
        <Warning>
          <p style={{fontWeight: 'bolder',fontSize:'20px', maxWidth: '800px', textAlign: 'center'}}>Room photographs on the website are indicative of the room category. There is a marginal
          difference in the decor, view and size of the room in the same category, however the facilities
          remain the same category wise.<br /> Room booking on preferred floor subject to availability.</p>
        </Warning>
      </Container>
    )
  }
}

const HeadingWithLine = ({text}) => (
  <StyledHeadingWithLine>
    {text}
    <div></div>
  </StyledHeadingWithLine>
);

const Container = styled.div`
  padding: 2rem 5rem;
  @media only screen and (max-width: 650px) {
    padding: 1rem 2rem;
  }
`;

const Heading = styled.div`
  text-align: center;
  font-family: Century Gothic;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
`;

const Grid = styled.div`
  width: 100%;
  max-width: 1000px;
  display: grid;
  padding: 1rem 0;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  grid-row-gap: ${({isService}) => isService ? '0' : '5rem'};
  grid-column-gap: 15px;
  @media (max-width: 800px) {
    grid-template-columns: auto auto;
    justify-content: space-around;
  }
  @media (max-width: 500px) {
    grid-template-columns: auto;
    justify-content: start;
  }
`;

const StyledHeadingWithLine = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 2rem;
  & > div {
    width: 30px;
    border-bottom: 2px solid;
    padding-top: 0.5rem;
  }
`;

const ListItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  max-width: 250px;
  font-size: 1.4rem;
  line-height: 2rem;
`;

const Item = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
`;

const Warning = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

`;

export default AmenitiesAndServices;