import React from 'react';
import styled from 'styled-components';

import LandingContent from '../../components/LandingContent';
import Footer from '../../components/Footer';
import Card from '../../components/StyledCard';
import theme from '../../utils/theme';
import { InHouseCardsContent, ArounTheCityCardsContent, } from '../../utils/ExperienceUtils';
// Carousal images
import defaultSrc1 from '../../images/Experiences/1.jpg';
import defaultSrc2 from '../../images/Experiences/2.jpg';
import defaultSrc3 from '../../images/Experiences/3.jpg';
import defaultSrc4 from '../../images/Experiences/4.jpg';
import defaultSrc5 from '../../images/Experiences/5.jpg';
import defaultSrc6 from '../../images/Experiences/6.jpg';
import defaultSrc7 from '../../images/Experiences/7.jpg';
import defaultSrc8 from '../../images/Experiences/8.jpg';
import defaultSrc9 from '../../images/Experiences/9.jpg';
import defaultSrc10 from '../../images/Experiences/10.jpg';
import defaultSrc11 from '../../images/Experiences/11.jpg';
import defaultSrc12 from '../../images/Experiences/12.jpg';
import defaultSrc13 from '../../images/Experiences/13.jpg';
import defaultSrc14 from '../../images/Experiences/14.jpg';


import img2 from '../../images/Experiences/GAME ROOM SMALL.png';
import img1 from '../../images/Experiences/IMG_7786.png';
import img3 from '../../images/Experiences/CHILDREN PLAY AREA SMALL.png';
import img4 from '../../images/Experiences/about.png';
import img5 from '../../images/Experiences/PUPPET SHOW SMALL (1).png';

import around1 from '../../images/Experiences/EXPLORE ON WHEELS SMALL.png';
import around2 from '../../images/Experiences/TREK A WHILE SMALL.png';
import around3 from '../../images/Experiences/NAKKI LAKE SMALL AND BIG.png';
import around4 from '../../images/Experiences/GURU SHIKHAR SMALL AND BIG.png';
import around5 from '../../images/Experiences/dilwara temple SMALL AND BIG.png';
import around6 from '../../images/Experiences/BRHMA KUMARI INTERNATIONAL HEADQUARTERS BIG AND SMALL.png';
import around7 from '../../images/Experiences/SUNSET POINT SMALL AND BIG.png';
import around8 from '../../images/Experiences/TREVORS TANK SMALL AND BIG.png';
import around9 from '../../images/Experiences/ACHALGARH FORT SMALL AND BIG.png';

const Experiences = (props) => {
  const inHouse = [img1, img2, img3, img4, img5];
  const around = [around1, around2, around3, around4, around5, around6, around7, around8, around9];
  return (
    <StyledContainer>
      <LandingContent images={[defaultSrc1, defaultSrc2, defaultSrc3, defaultSrc4, defaultSrc5, defaultSrc6, defaultSrc7, defaultSrc8, defaultSrc9, defaultSrc10, defaultSrc11, defaultSrc12, defaultSrc13, defaultSrc14]} />
      <StyledText>
        <h1 style={{ fontFamily: 'OFL-Sorts', fontStyle: 'italic' }}>Unwind through experiences</h1>
        <br />
        <p style={{textAlign: 'center'}}>
          Explore, unwind and give yourself a natural detox in and around
          our property situated at the foothills of the undulating
          Aravalli Hills. A visit to Mount Abu, the only hill station
          in Rajasthan will reveal several breathtaking locations and
          awe-inspiring temples to the eager eye, and fill your days with
          relaxation and serenity. With so much to fill your time, no
          moment spent here will be dull. Let us take you through various
          in-house and around the city experiences.
        </p>
      </StyledText>
      <StyledH2 style={{ marginTop: '2rem' }}>In-House</StyledH2>
      <ContentFlex>
        {
          InHouseCardsContent.map(({ heading, timings, text,speacial }, i) => (
            <Card src={inHouse[i]} key={"inCity" + i} timings={timings} heading={heading} speacial={speacial} text={text} />
          ))
        }
      </ContentFlex>
      <StyledH2 style={{ marginTop: '3rem' }}>Around the City</StyledH2>
      <Content style={{marginBottom: '4rem'}}>
        {
          ArounTheCityCardsContent.map(({ heading, text }, i) => (
            <Card src={around[i]} key={"around" + i} heading={heading}  text={text} />
          ))
        }
      </Content>
      <Footer />
    </StyledContainer>
  )
}

export default Experiences;

const StyledContainer = styled.div`
  color: ${theme.primary};
`;

const StyledText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 2rem 20%;
  font-size: 1.4rem;
  @media (max-width: 800px) {
    margin: 5%;
  }
`;

const StyledH2 = styled.h2`
  font-family: 'OFL-Sorts';
  font-style: italic;
  text-align: center;
  font-size: 2rem;
`;

const Content = styled.div`
  width: 100%;
  display: grid;
  padding: 1rem 0;
  grid-template-columns: auto auto auto;
  justify-content: space-around;
  grid-row-gap: 5rem;
  grid-column-gap: 15px;
  @media (max-width: 1050px) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 700px) {
    grid-row-gap: 3rem;
    grid-template-columns: auto;
  }
`;

const ContentFlex = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  & > div {
    margin: 5rem;
  }
  @media (max-width: 1050px) {
    & > div {
      margin: 3rem;
    }
  }
  @media (max-width: 850px) {
    & > div {
      margin: 1.5rem;
    }
  }
  @media (max-width: 700px) {
    & > div {
      margin: 3rem;
    }
  }
`;