import React from 'react';
import styled from 'styled-components';

import LandingContent from '../../components/LandingContent';
import Footer from '../../components/Footer';
import colors from '../../utils/theme';
import FancyButton from '../../components/FancyButton';
// Carousal images
import defaultSrc1 from '../../images/rasah/1.jpg';
import defaultSrc2 from '../../images/rasah/2.jpg';
import defaultSrc3 from '../../images/rasah/3.jpg';
import defaultSrc4 from '../../images/rasah/4.jpg';
import defaultSrc5 from '../../images/rasah/5.jpg';
import defaultSrc6 from '../../images/rasah/6.jpg';
import defaultSrc7 from '../../images/rasah/7.jpg';
import defaultSrc8 from '../../images/rasah/8.jpg';
const wellNess = "https://hotelhilltone.com/menu/wellness.pdf";
const Wellness = (props) => (
  <React.Fragment>
    <LandingContent images={[defaultSrc1, defaultSrc2, defaultSrc3, defaultSrc4, defaultSrc5, defaultSrc6, defaultSrc7, defaultSrc8]} />
    <Container>
      <StyledH2>Relax, Revitalise And Rejuvenate</StyledH2>
      <Content>
        <p>Rasáh comes from the Sanskrit translation of ‘the essence of life’.</p>
        <p>
          Although the hills surrounding the Resort are a balm to your senses, experience Rasáh, our spa, to loose yourself
          to our gentle and experienced masseurs as they release the stress and tension from your body and mind. Our
          healers with their art use the five senses to immerse you in a journey that creates a whole new aura around you.
        </p>
        <p>
          Take your pick from our holistic therapies to escape and indulge yourself in exquisite wellness treatments in our
          two treatment suites which inbuilt three feature washrooms and steam.
        </p>
        <p>Rasáh welcomes you from 9:00 am to 9:00 pm.</p>
        <p>
          Rasáh team can be contacted for enquiries/reservations at reservations@hotelhilltone.com & +91 9610750888

        </p>
      <a href={wellNess} target="_blank" rel="noopener noreferrer">
                <FancyButton text="Menu" color='#b88a00' />
              </a>
      </Content>
    </Container>
    <Footer />
  </React.Fragment>
);

export default Wellness;

const Container = styled.div`
  margin: 4vw 3vw;
  @media only screen and (max-width: 650px) {
    margin: 0;
    margin-top: 2rem;
  }
`;

const StyledH2 = styled.h2`
  font-family: 'OFL-Sorts';
  font-style: italic;
  color: ${colors.primary};
  margin: 2.5rem;
  margin-top: 0;
  text-align: center;
`;

const Content = styled.div`
  font-family: 'OFL-Sorts';
  color: ${colors.primary};
  margin: 2.5rem;
  margin-top: 0;
  font-size: 1.4rem;
  text-align: center;
`;