import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import theme from '../../utils/theme';

import i1 from '../../images/photo-gallery/1.jpg';
import i3 from '../../images/photo-gallery/3.jpg';
import i0 from '../../images/landing_img1.jpg';
import i4 from '../../images/photo-gallery/4.jpg';
import i5 from '../../images/photo-gallery/5.jpg';
import i6 from '../../images/photo-gallery/7.jpg';
import i7 from '../../images/photo-gallery/8.jpg';
import i8 from '../../images/photo-gallery/9.jpg';
import i9 from '../../images/photo-gallery/10.jpg';
import i10 from '../../images/photo-gallery/11.jpg';
import i11 from '../../images/photo-gallery/12.jpg';
import i12 from '../../images/photo-gallery/13.jpg';
import i13 from '../../images/photo-gallery/14.jpg';
import i14 from '../../images/photo-gallery/15.jpg';
import i15 from '../../images/photo-gallery/16.jpg';
import i16 from '../../images/photo-gallery/17.jpg';
import i17 from '../../images/photo-gallery/18.jpg';
import i18 from '../../images/photo-gallery/19.jpg';
import i19 from '../../images/photo-gallery/20.jpg';
import i20 from '../../images/photo-gallery/21.jpg';
import i21 from '../../images/photo-gallery/22.jpg';
import i22 from '../../images/photo-gallery/23.jpg';
import i23 from '../../images/photo-gallery/24.jpg';
import i24 from '../../images/photo-gallery/25.jpg';
import i25 from '../../images/photo-gallery/26.jpg';
import i26 from '../../images/photo-gallery/27.jpg';

let sliderTimer;

class SmallSliderHome extends React.Component {
  static propTypes = {
    images: PropTypes.array.isRequired,
    isHome: PropTypes.bool,
  }

  static defaultProps = {
    images: [i1, i3, i0, i4, i5, i6, i7, i8, i9, i10],
    isHome: false,
  }

  state = {
    index: 1,
    imagesToRender: null,
    playing: true,
    currentDragXPos: null,
    renderAllowed: true,
  }

  componentDidMount() {
    this.setSliderTimer();
  }

  setSliderTimer = () => {
    sliderTimer = setInterval(() => this.renderNextImage(), 3000);
  }

  componentWillUnmount() {
    clearInterval(sliderTimer);
  }

  renderNextImage = () => {
    const { index, imagesToRender } = this.state;
    const { images } = this.props;

    const lastIndex = (imagesToRender ? imagesToRender.length : images.length) - 1;
    let newImages;

    if(index >= lastIndex - images.length) {
      // console.log(imagesToRender);
      const firstArray = imagesToRender ? imagesToRender : images;
      newImages = [...firstArray, ...images];
      this.setState({ index: index + 1, imagesToRender: newImages })
    } else if(!imagesToRender) {
      this.setState({ index: index + 1, imagesToRender: images })
    } else {
      this.setState({ index: index + 1, });
    }
  }

  renderPreviousImage = () => {
    const { index, imagesToRender } = this.state;
    const { images } = this.props;

    if(index <= 1 || !imagesToRender) {
      this.setState({ index: images.length - 2, imagesToRender: images })
    }else {
      this.setState({ index: index - 1 });
    }
  }

  render() {
    const { index, imagesToRender } = this.state;
    const sliderImages = imagesToRender ? imagesToRender : this.props.images;

    // Carousel has following default features
    // 1. Auto rotate right
    // 2. Circular scrolling of images
    // 3. central Image will have full height
    // 4. Other image will be 95% height
    return (
      <React.Fragment>
        <StyledContent {...this.props}>
          <ImageContainer
            onTouchMove={e => {
              console.log('dsaf', e.changedTouches[0]);
              const {screenX} = e.changedTouches[0];
              const {currentDragXPos, renderAllowed} = this.state;

              if(!currentDragXPos && renderAllowed) {
                console.log('1');
                this.setState({currentDragXPos : screenX});
              } else if(currentDragXPos && renderAllowed) {
                const diff = currentDragXPos - screenX;
                console.log('2');
                this.setState({
                  currentDragXPos: null, playing: false, renderAllowed: false,
                }, () => {
                  clearInterval(sliderTimer);
                  diff > 10 ? this.renderNextImage() : this.renderPreviousImage()
                });
              }
            }}
            onTouchEnd={e => this.setState({renderAllowed: true})}
          >
            {
              sliderImages.map((image, i) => <Image
                onDragStart={e => e.preventDefault()}
                src={image}
                style={{
                  transform: `translate(-${index * 100}%, 0)`,
                  margin: index === i ? '0' : '5% 0',
                  filter: index === i ? 'none' : 'grayscale(100%)',
                  zIndex: index === i ? '1' : '0',
                }}
                key={'carousel-c-small' + i}
              />)
            }
          </ImageContainer>
        </StyledContent>
        <ControlButton>
          <Button onClick={() => {
            clearInterval(sliderTimer);
            this.setState({pause: true}, () => this.renderPreviousImage());
          }}><Arrow /></Button>
          <Button onClick={() => {
            clearInterval(sliderTimer);
            this.setState({pause: true}, () => this.renderNextImage());
          }}><Arrow right={true} /></Button>
        </ControlButton>
      </React.Fragment>
    );
  }

  playAndPause = (e) => {
    const { playing } = this.state;
    playing ? clearInterval(sliderTimer) : this.setSliderTimer();
    this.setState({ playing: !playing });
  }
}

export default SmallSliderHome;

const Image = styled.img`
  min-width: 80%;
  transition: 0.6s ease-in-out;
  @media only screen and (max-width: 800px) {
    min-width: 100%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 30%;
  min-height: 400px;
  max-height: 450px;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 16.75%;
  @media only screen and (max-width: 800px) {
    padding-left: 0;
  }
`;

const StyledContent = styled.div`
  display: flex;
  width: 100%;
  height: 30%;
  min-height: 400px;
  max-height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center;
  background-attachment: local;
  position: relative;
`;

const ControlButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Button = styled.button`
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  background: ${theme.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Arrow = styled.i`
  border: solid #fff;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: ${({right}) => right
    ? 'rotate(-45deg); margin-left: -2px;'
    : 'rotate(135deg); margin-left: 5=2px;'
  };
`;