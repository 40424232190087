import React from 'react';
import styled from  'styled-components';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import { Modal } from 'semantic-ui-react';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import FancyButton from '../../components/FancyButton';

const TextWithSlider = ({ SupportImages, ButtonText, flipOrder, ModalContent }) => {
  return (
    <Container>
      <ButtonContainer flipOrder={flipOrder}>
        <Modal dimmer={"blurring"} trigger={
          <FancyButton text={ButtonText} height={'60px'} />
        } centered={false} closeIcon >
          <Modal.Content>
            { ModalContent ? ModalContent() : '' }
          </Modal.Content>
        </Modal>
      </ButtonContainer>
      <ImageContainer>
        <Carousel
          infiniteLoop
          showThumbs={false}
          autoPlay
          dynamicHeight
          stopOnHover
          emulateTouch
          interval={5000}
          transitionTime={700}
          showStatus={false}
        >
          {
            SupportImages.map((x,i) => (
              <div key={"TextWithingImageCarousel" + i}>
                <StyledImage alt="#" src={SupportImages[i]} />
              </div>
            ))
          }
        </Carousel>
      </ImageContainer>
    </Container>
  )
}

export default TextWithSlider;

TextWithSlider.propTypes = {
  ButtonText: PropTypes.string.isRequired,
  SupportImages: PropTypes.array.isRequired,
  flipOrder: PropTypes.bool,
}

TextWithSlider.defaultProps = { flipOrder: false };

const Container = styled.div`
  display: flex;
  margin: 5vw 3vw;
  @media (max-width: 850px) {
    flex-direction: column;
    & > div {
      width: 100%;
      margin: 0;
    }
    & > div:first-child {
      order: 2;
    }
    & > div > button {
      font-size: 1.2rem;
      height: 2.5rem;
    }
  }
`;

const ButtonContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 850px) {
    ${({flipOrder}) => flipOrder && 'order: 2;'}
  }
`;

const ImageContainer = styled.div`
  width: 50%;
  margin: 0 5%;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;