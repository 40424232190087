import React from 'react';
import styled from 'styled-components';

const SocialIcons = (props) => (
  <React.Fragment>
    <Icon bgColor={"#3b5998"}>
      <StyledAnchor target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/hotelhilltone/">
        <i className="fa fa-facebook"></i>
      </StyledAnchor>
    </Icon>
    <Icon bgColor={"#1da1f2"}>
      <StyledAnchor target="_blank" rel="noopener noreferrer" href="https://twitter.com/hotelhilltone">
        <i className="fa fa-twitter"></i>
      </StyledAnchor>
    </Icon>
    <Icon bgColor={"#f46f30"}>
      <StyledAnchor target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/hilltonehotel/?hl=en">
        <i className="fa fa-instagram"></i>
      </StyledAnchor>
    </Icon>
    <Icon bgColor={"#FF0000"}>
      <StyledAnchor target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/user/hotelhilltone">
        <i className="fa fa-youtube"></i>
      </StyledAnchor>
    </Icon>
  </React.Fragment>
);

export default SocialIcons;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 60px;
  height: 60px;
  font-size: 30px;
  padding: 20px;
  border: 1px solid #fff;
  border-radius: 50%;
  margin: 0 10px;
  background-color: transparent;
  color: white;
  &:hover {
    background-color: ${props => props && props.bgColor};
    color: white;
    transition: background-color 0.3s ease;
    box-shadow: 0 15px 20px 0px #b1b1b1;
  }
  transition: background-color 0.3s ease;
`;

const StyledAnchor = styled.a`
  color: #FFFFFF;
  &:hover {
    color: #fff;
  }
`;