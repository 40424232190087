import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import './about.css';
import Footer from '../../components/Footer';
import theme from '../../utils/theme';
import FancyButton from '../../components/FancyButton';
import Header from '../../components/Header';

import defaultImg2 from '../../images/about_main_img.jpg';
import logoImg from '../../images/hilltone1.png';

const AboutUs = (props) => {
  const ButtonTexts = ['Lush Green Gardens', 'Mulberry Tree Restaurant',
    'Risa Bar & Cafe', 'Rasah Spa', 'Swimming Pool (Under maintenance)', 'Children\'s Play Area',
    'Games Room','Meetings & Events', 'EV Charging Station'
  ];

const description1 = () => (
    <div>
      
      <br />

      <Subheading>Experience Absolute Luxury</Subheading>
          <p>
            Situated away from the usual hustle bustle of the town, Hotel Hilltone is one
            of the finest hotel-resorts in Mount Abu that offers you the solace that you are
            looking for. Nestled amidst the centuries old undulating Aravalli Hills, we offer
            relaxation coupled with style, warm hospitality and unmatched service.
          </p>
          <p>
            Our luxury resort features 68 rooms inclusive of suites and villas set around 2
            acres of serene land. It is ideally located in the heart of all that Mount Abu
            has to offer. At the forefront of the luxury hotel scene for more than 5 decades,
            Hotel Hilltone has been welcoming guests for an experience that continuously evolves
            to meet the needs of the modern day leisure traveler, providing a fine blend of world-class
            comfort and splendid beauty.
          </p>
          <p>
            Mount Abu is the only hill station shared between the Royal state of Rajasthan and Gujarat,
            located in the majestic Aravalli ranges at 4000 feet. Hotel Hilltone is very much a part of
            Mount Abu’s history.
          </p>
      <br />

    </div>
  );


  return (
    <StyledContainerAbout>
      {/* <LandingContent
        images={[defaultImg1, defaultImg2, defaultImg3]}
      /> */}
      
      <Logo to="/" style={{paddingTop: '100px'}}>
       
      </Logo>
      <CenterContent />
      <Header white={true} />
      <br />
      

      
    <SuiteBody>
    <Container>
    <StyledDescriptionContent>{description1()}</StyledDescriptionContent>
    
    <MenuButtonContainer>
               
              <table>
              
              <tr><th>FACILITIES</th>
              </tr>
              <tr> <td></td>
              </tr>
              
              {ButtonTexts.map((text, i) => {
                  return (
                    <tr key={'button' + i}>
                      <td>{text}</td>
                    </tr>

                  )
                })}
              
              </table>
    </MenuButtonContainer>
    
   
    </Container>
    </SuiteBody>
      <Footer />
    </StyledContainerAbout>
  );
}

export default AboutUs;

const Logo = styled(Link)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 10%;
`;

const CenterContent = styled.div`
  height: 80%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
`;

const StyledContainerAbout = styled.div`
  max-width: 100vw;
  height: 100vh;
  background-image: linear-gradient( rgba(0,0,0,.35), rgba(0,0,0,.35) ), url(${defaultImg2});
  background-repeat: no-repeat;
  background-width: 100%;
  background-size: cover;
  background-position:center;
  background-attachment: local;
`;

const TextContainer = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  text-align: center;
  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
`;

const Subheading = styled.h1`
  font-family: 'OFL-Sorts';
  font-style: italic;
  margin-bottom: 3rem;
  text-align: center;
  color: ${theme.primary};
`;

const Text = styled.div`
  margin: 2rem;
  margin-top: 0;
  width: 90%;
  font-size: 1.4rem;
  font-family: Century Gothic;
  line-height: 1.8;
  text-align: center;

  color: #6D6326;
`;

const StyledChips = styled.div`
  width: 90%;
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
  line-height: 2.2;
  padding: 10px 0;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;

  @media only screen and (max-width: 700px) {
    width: 95%;
  }
`;
const Container = styled.div`
  width: 100%;
  margin: 0 2rem;
  display: grid;
  font-size: 1.3rem;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
    margin: 0;
  }
`;

const SuiteBody = styled.div`
  margin: 8vh 2vw;
  @media only screen and (max-width: 850px) {
    margin-top: 60px;
  }
`;
const StyledDescriptionContent = styled.div`
  display: flex;
  height: calc(100% - 4rem);
  flex-direction: column;
  justify-content: center;
  padding-right: 2rem;
  font-family:Soria;
  @media only screen and (max-width: 800px) {
    padding-right: 0;
  }
  margin: 0 1rem;
`;

const MenuButtonContainer = styled.div`
  display: flex-direction;
  width: 100%;
  font-family:Soria;
  
`;



