import React, { useState, } from 'react';
import styled from 'styled-components';
import { Modal, Button, TextArea, Form } from 'semantic-ui-react';

import 'semantic-ui-css/semantic.min.css'

import LargeTextWithLine from '../../components/LargeTextWithLine';
import colors from '../../utils/theme';
import QA from '../../utils/QuestionAnswers';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import risa from '../../images/ads/logorisa.png';
import hill from '../../images/ads/hilltonelogo.png';
import spa from '../../images/ads/sparash.png';
import mul from '../../images/ads/mul.png';

const Contact = () => {
  return (
    <React.Fragment>
      <div style={{ position: '' }}>

      
        
      <div>
          
          <a href="https://www.hotelhilltone.com/" target="_blank" rel="noreferrer">
          <img src={hill} alt="Logo" style={{ "width":"8%","margin-left":'13%','margin-top':'-2%' }}/>
          </a>
          <a href="https://www.hotelhilltone.com/#/dining/" target="_blank" rel="noreferrer">
          <img src={mul} alt="Logo" style={{ "width":"12%","margin-left":'12%' }}/>
          </a>
          <a href="https://www.hotelhilltone.com/#/dining" target="_blank" rel="noreferrer">
          <img src={risa} alt="Logo" style={{ "width":"5%","margin-left":'12%',"margin-top":'1%' }}/>
          </a>
          <a href="https://www.hotelhilltone.com/#/wellness" target="_blank" rel="noreferrer">
          <img src={spa} alt="Logo" style={{ "width":"4%","margin-left":'15%' }}/>
          </a>
      </div>
        <Iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3627.9950020788!2d72.713931!3d24.589368699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395d2a79120602ab%3A0xe7350395980e2836!2sHotel%20Hilltone!5e0!3m2!1sen!2sin!4v1566843105721!5m2!1sen!2sin"
        ></Iframe>
        
        <Header />
      </div>

      <Container>
        
        
        <LargeTextWithLine text={"HOTEL POLICY"} width={"200px"} />
        <LargeText>
          <p style={{ lineHeight: '1em', fontSize: 28 ,fontWeight: 'bold' }}>Cancellation Policy · Terms & Conditions</p>
          <br />
          <p style={{ lineHeight: '0.3em', fontSize: 24,fontWeight: 'bold' }}>A. Arrival, Departure & Modification: </p>
         
          <div style={{ marginLeft:20 }}>
               <p style={{ lineHeight: '1.2em' }}> <b>1. Check-in</b> time 1:00 pm <br /> <b>2. Check out</b> time 11:00 am <br /> <b>3</b>. Any extension after 11:00 am until 6:00pm will be charged at 50% of room rate. <br /> <b>4.</b> Late check-out after 6:00 pm will be charged one day room rate. <br />  <b>5. Reservation modification</b>: All changes to your reservation would subject to availability at the time
 of amendment and may result in possible rate change.</p>

          </div>
          <br />
          <p style={{ lineHeight: '0.3em', fontSize: 24,fontWeight: 'bold' }}>B. Extra Occupancy & Children:</p>
         
          <div style={{ marginLeft:20 }}>
               <p style={{ lineHeight: '1.2em' }}> <b>1.</b> Accommodation for children up to 05 years shall be provided on complimentary basis. <br /> <b>2.</b> Extra child 6 to 11 years and adult above 11 years will be charged extra as per our tariff rate.<br /> <b>3.</b> Maximum Occupancy in a room is either 3 adults or 2 adults with 2 children (below 11 years) on extra charges.<br /> <b>4.</b> We shall provide a roll out bed or an extra mattress for extra occupancy in the same room.</p>

          </div>
          <br />
          <p style={{ lineHeight: '0.3em', fontSize: 24,fontWeight: 'bold' }}>C. Cancellation & Refund Policy:</p>
         
          <div style={{ marginLeft:20 }}>
               <p style={{ lineHeight: '1.2em' }}> <b>1.</b> 100% refund if cancellation is affected before 15 days of the <b>Check-in</b> date.<br /> <b>2.</b> 75% refund if cancellation is affected between 07 to 14 days of the <b>Check-in</b> date.<br /> <b>3.</b> 50% refund if cancellation is affected between 04 to 06 days of the <b>Check-in</b> date.<br /> <b>4.</b> No refund if cancellation is affected within 03 days of the check in date.<br /> <b>5.</b> In case of no show (NON-ARRIVAL) booking, full amount would be retained.</p>

          </div>
          <br />
          <p style={{ lineHeight: '0.3em', fontSize: 24,fontWeight: 'bold' }}>D. Other Booking Conditions:</p>
         
          <div style={{ marginLeft:20 }}>
               <p style={{ lineHeight: '1.2em' }}>
                  <b>1.</b> The primary guest must be at least 18 years to be able for check in.<br />
                  <b>2.</b> As per the Government of India rules in lieu with guest safety and security procedure, it is mandatory to
                     present either of the following photo ID proof – Aadhaar card, Voter ID, Driving License or Passports for all
                     the guests, check in can be denied for lack thereof.<br />
                  <b>3.</b> All foreign national guests or Non-Resident Indian (NRI) must submit their passport and visa at check in.<br />
                  <b>4.</b> As per the Government of India rules in lieu with the guest safety and security, the hotel may deny
                     accommodation in case of failure to furnish proper identification. The hotel reserves the RIGHT TO ADMISSION,
                     accommodation can be denied to the guests with confirmed reservation if the booking information
                     records do not match the guest profile or if the hotel management feels it in the violation of hotel policy.<br />
                  <b>5.</b> The hotel reserves the right to cancel or amend reservations if the guests do not abide by hotel T & C.<br />
                  <b>6.</b> Should any action by a guest be deemed inappropriate by the hotel, or if any inappropriate behaviour
 or illegal activity is brought to the attention of the hotel, the hotel reserves the right, after the
 allegations have been investigated to take action against the guest.<br />
                  <b>7.</b> Guest shall be liable for any damage to any Hotel asset.<br />
                  <b>8.</b> Room photographs on the website are indicative of the room category. There is a marginal difference
 in the décor and the size of the rooms in the same category however the facilities remain the same
 room category wise.<br />
                    <b>9.</b>The comfort and convenience of all our guests is of utmost importance to us. To facilitate the same, no loud music
or noise is permissible in the Hotel premises. Fellow guests are requested to refrain from indulging in any practices which would lead to disturbing other guests. In case of disturbance, it would become imperative for the management to take appropriate action.<br />
                  <b>10.</b> As per local laws Gambling and use of Hookah is strictly prohibited in the property.<br />
                  <b>11.</b> COVID-19 rules to be followed as per Govt. of India / Govt. of Rajasthan guidelines.<br />
                  <b>12.</b> Visitor Policy - Visitors would not be allowed in guest rooms or guest floor, however they can meet the guests in
 hotel lobby or outlets.<br />
               </p>

          </div>
          <br />
        </LargeText>
        
        <FAQ>
          <LargeTextWithLine text={"FAQ"} width={"200px"} />
          {
            QA.map((qa, i) => (
              <Accordion
                qa={qa}
                key={"qa" + i}
              />
            ))
          }
        </FAQ>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

function Accordion({ qa }) {
  const [on, setOn] = useState(false);
  const { question, answer, noLineHeight } = qa;

  return (
    <div>
      <Question onClick={e => setOn(!on)}>
        {on ? '-' : '+'} {question}
      </Question>
      <Answer on={on}>
        {
          Array.isArray(answer) ? (
            answer.map((a, j) => noLineHeight
              ? <p style={{ margin: 0 ,fontWeight:'1.6rem'}} key={question + j}>{a}</p>
              : <p style={{ marginBottom: '0.6rem' }} key={question + j}>{a}</p>)
          ) : answer
        }
      </Answer>
    </div>
  );
}

export default Contact;


const Container = styled.div`
  color: ${colors.primary};
  padding: 3rem 2rem;
  @media only screen and (max-width: 800px) {
    padding: 2rem 0;
  }
`;

const Iframe = styled.iframe`
  width: 100vw;
  height: 87vh;
`;



const LargeText = styled.div`
  font-size: 1.3rem;
  margin-left: 2rem;
  @media only screen and (max-width: 800px) {
    padding-top: 2rem;
  }
`;

const Email = styled.div`
  display: flex;
  width: 100%;
  margin: 4rem 0;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    & > a {
      margin-left: 2rem;
      margin-top: 1rem;
    }
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
`;

const FAQ = styled.div`
  padding: 2rem;
`;

const Question = styled.div`
  font-weight: bold;
  user-select: none;
  font-size: 1.4rem;
  margin-top: 2rem;
  cursor: pointer;
`;

const Answer = styled.div`
  margin-left: 10px;
  line-height: 1.8rem;
  font-size: 1.3rem;
  padding-top: 1rem;
  display: ${({ on }) => on ? 'block' : 'none'};
`;

const ads = styled.div`
  margin-left: 10px;
  line-height: 1.8rem;
  width:100%;
`;




//export default Handle;