import React from 'react';
import styled from 'styled-components';
import { Modal, Button, Form, Select } from 'semantic-ui-react';

import { HallData1, HallData2 } from '../../utils/MeetingsAndEvents';
import TextWithSlider from '../../components/TextWithSlider';
import FancyButton from '../../components/FancyButton';
import theme from '../../utils/theme';

import img11 from '../../images/Meetings and event/hall1/1.jpg';
import img21 from '../../images/Meetings and event/hall1/2.jpg';
import img31 from '../../images/Meetings and event/hall1/3.jpg';
import img41 from '../../images/Meetings and event/hall1/4.jpg';
import img51 from '../../images/Meetings and event/hall1/5.jpg';


import img111 from '../../images/Meetings and event/hall2/1.jpg';
import img211 from '../../images/Meetings and event/hall2/2.jpg';
import img311 from '../../images/Meetings and event/hall2/3.jpg';
import img411 from '../../images/Meetings and event/hall2/4.jpg';
import img511 from '../../images/Meetings and event/hall2/5.jpg';

import img112 from '../../images/Meetings and event/board-room/1.jpg';
import img212 from '../../images/Meetings and event/board-room/2.jpg';

import img113 from '../../images/Meetings and event/main-lawn/1.jpg';
import img213 from '../../images/Meetings and event/main-lawn/2.jpg';
import img313 from '../../images/Meetings and event/main-lawn/3.jpg';
import img413 from '../../images/Meetings and event/main-lawn/4.jpg';

import img513 from '../../images/Meetings and event/poolside-lawn/1.jpg';
import img613 from '../../images/Meetings and event/poolside-lawn/2.jpg';


const options = [
  { key: 'p', text: 'Parishad Hall I', value: 'parishad Hall I' },
  { key: 'p2', text: 'Parishad Hall II', value: 'parishad Hall II' },
  { key: 'b', text: 'Board Room', value: 'board Room' },
  { key: 'po', text: 'PoolSide Lawn', value: 'poolSide Lawn' },
  { key: 'ma', text: 'Main Lawn', value: 'main Lawn' }
];

const Content = (props) => {
  return (
    <Container>
      {/* <LargeTextWithLine width={"75px"} text={"Meetings and Events"} /> */}
      <TextContainer>
        <Text>
          <Subheading>Personalized Unforgettable Events</Subheading>
          <StyledPara>
            Hotel Hilltone offers the finest banqueting and garden spaces
            that boasts of modern amenities, latest technology, lavish interiors
            and hospitable staff who pamper you like royalty. Be it a conference
            or grand wedding celebration, we offer the perfect setting with professional
            event planning, customized menus and dedicated catering team, with a promise
            to exceed your every expectation. A combination of indoor and outdoor event
            spaces, each with a different area and capacity, every event at Hotel Hilltone
            is executed with uniqueness customized to your needs.
            </StyledPara>
        </Text>
        <Subheading style={{ margin: '2rem 0 0 0', fontSize: '2.5rem' }}>Our Venues</Subheading>
        <TextWithSlider
          ButtonText={"Parishad Hall I"}
          SupportImages={[img11, img21, img31, img41, img51]}
          ModalContent={() => <Format1 data={HallData1.parishadHall1} />}
        />
        <TextWithSlider
          ButtonText={"Parishad Hall II"}
          SupportImages={[img111, img211, img311, img411, img511]}
          flipOrder={true}
          ModalContent={() => <Format1 data={HallData1.parishadHall2} />}
        />
        <TextWithSlider
          ButtonText={"Board Room"}
          SupportImages={[img112, img212]}
          ModalContent={() => <Format1 data={HallData1.boardRoom} />}
        />
        <TextWithSlider
          ButtonText={"Main Lawn"}
          SupportImages={[img113, img213, img313, img413]}
          flipOrder={true}
          ModalContent={() => <Format2 data={HallData2.mainLawn} />}
        />
        <TextWithSlider
          ButtonText={"Poolside Lawn"}
          SupportImages={[img513, img613]}
          ModalContent={() => <Format2 data={HallData2.poolsideLawn} />}
        />
        
        <a href="https://api.whatsapp.com/send?phone=919414154888&text=Hello Hotel Hilltone" rel="noopener noreferrer" target="_blank">
              <FancyButton height={"45px"} text="Request a Proposal" />
           </a>
        
      </TextContainer>
    </Container>
  );
}

const Format1 = ({ data }) => {
  const { heading, text, text2, dimensions, facilities } = data;
  return (
    <ModalContainer>
      <Heading>{heading}</Heading>
      <p style={{ fontSize: '1.2rem', textAlign: 'center' }}>{text}</p>
      {
        text2 && <p style={{ fontSize: '1.2rem', textAlign: 'center' }}>{text2}</p>
      }
      <TableContainer>
        <StyledTable>
          <StyledTr>
            <StyledTh>Dimensions (in feet) LxB	</StyledTh>
            <StyledTh>{dimensions.main}</StyledTh>
          </StyledTr>
          <StyledTr>
            <StyledTd>Theatre</StyledTd>
            <StyledTd>{dimensions.theatre}</StyledTd>
          </StyledTr>
          <StyledTr>
            <StyledTd>Classroom</StyledTd>
            <StyledTd>{dimensions.classroom}</StyledTd>
          </StyledTr>
          <StyledTr>
            <StyledTd>U-Shape</StyledTd>
            <StyledTd>{dimensions.uShape}</StyledTd>
          </StyledTr>
          <StyledTr>
            <StyledTd>Sit Down</StyledTd>
            <StyledTd>{dimensions.sitDown}</StyledTd>
          </StyledTr>
          <StyledTr>
            <StyledTd>Cocktails</StyledTd>
            <StyledTd>{dimensions.cockTails}</StyledTd>
          </StyledTr>
        </StyledTable>
      </TableContainer>
      <p style={{ marginTop: '2rem', display: 'flex', fontSize: '1.2rem' }}>
        <span style={{ fontWeight: 'bold' }}>Facilities:&nbsp;</span>
        <span>{facilities}</span>
      </p>
    </ModalContainer>
  );
}

const Format2 = ({ data }) => {
  const { heading, boldText, text } = data;
  return (
    <ModalContainer>
      <Heading>{heading}</Heading>
      <Bold style={{ fontSize: '1.2rem' }}>{boldText}</Bold>
      <p style={{ fontSize: '1.2rem',textAlign: 'center' }}>{text}</p>
    </ModalContainer>
  );
}

export default Content;
// const Button = styled.div`
// background-color: #7E7647;
// `;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5vw 3vw;
  @media (max-width: 800px) {
    margin: 3vw 1.5vw; 
    & > div > div {
      width: 100%;
    }   
  }
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    margin-top: 1.2rem;
    justify-content: flex-start;
  }
`;

const Subheading = styled.h2`
  font-family: 'OFL-Sorts';
  font-style: italic;
  margin-bottom: 2rem;
  color: ${theme.primary};
`;

const StyledPara = styled.p`
  text-align: center;
  margin: 2rem 20%;
  font-size: 1.4rem;
  color: ${theme.primary};
  @media (max-width: 800px) {
    margin: 5%;
  }
`;

const Text = styled.div`
  margin-top: 0;
  text-align: center;
`;

const ModalContainer = styled.div`
  font-family: 'Century Gothic';
  color: ${theme.primary};
  padding: 2rem 3rem;
  @media only screen and (max-width: 700px) {
    padding: 2rem;
  }
`;

const Heading = styled.div`
  font-size: 1.5rem;
  font-style: italic;
  text-align: center;
  font-weight: bold;
  margin-bottom: 2rem;
`;

const TableContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 2rem 0;
`;

const StyledTable = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
`;

const StyledTd = styled.td`
  border: 1px solid #000;
  text-align: left;
  padding: 8px;
`;

const StyledTh = styled.th`
  border: 1px solid #000;
  text-align: left;
  padding: 8px;
`;

const StyledTr = styled.tr`
  font-size: 1.2rem;
  &:nth-child(odd) {
    background-color: ${theme.primary};
    color: white;
  }
`;

const Bold = styled.div`
  text-align: center;
  font-weight: bold;
  padding-bottom: 1rem;
`