import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledCard = ({src, heading, timings, text,speacial}) => (
  <Card>
    <Image src={src} />
    <Heading>{heading}</Heading>
    <Heading>{speacial}</Heading>
    <br />
    <p style={{ textAlign: "center"}}>{text}</p>
    {
      timings && 
      <div>
        <p style={{fontWeight: 'bold', margin: '5px 0'}}>Timing:</p>
        <div>
          {timings.map(str => <p style={{margin: '5px 0'}} key={str}>{str}</p>)}
        </div>
      </div>
    }
  </Card>
)

StyledCard.propTypes = {
  src: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

StyledCard.defaultProps = {
  src: '...',
  heading: "Heading",
  text: "text"
}

export default StyledCard;

const Card = styled.div`
  width: 20vw;
  min-width: 300px;
  padding: 1rem;
  font-size: 1.4rem;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
`;

const Heading = styled.h3`
  font-family: OFL-Sorts;
  font-style: italic;
  text-align: center;
  margin-top: 2rem;
  font-size: 1.6rem;
`;
