const InHouseCardsContent = [
    {
        heading: 'Swimming Pool',
        text: "The outdoor pool area features the main pool with a depth of 7 feet and the other one with a depth of 2 feet for kids only. A dive into our pool will help to start your day with higher levels of energy.",
        timings: ['Summer 9:00 am to 6:00 pm', 'Winter 10:00 am to 5:00 pm'],
    },
    {
        heading: 'Games Room',
        text: 'Vacations become more enjoyable with recreation activities. Equipped with Billiards, Table Tennis, Carrom and board games, our games room ensures that your kids will never go short of fun.'
    },
    {
        heading: 'Children\'s Play Area',
        text: 'While you enjoy your holiday at our hotel, we ensure that your children have fun time too.'
    },
    {
        heading: 'Rajasthani Folk Music',
        text: 'Folk music of Rajasthan attracts every listener from its sensuous rhythm. Wind up your evening with live performance by our professional singers in the lawns of the hotel.',
        speacial :'(Seasonal)'
    },
    {
        heading: 'Puppet Show',
        text: 'Kathputli is a string puppet theatre, native to Rajasthan, India and is the most popular form of Indian puppetry. Experience these exclusive shows every evening.',
        speacial :'(Seasonal)'
    }
];

const ArounTheCityCardsContent = [
    {
        heading: 'Explore on wheels',
        text: 'Why not stay fit even when you are on your vacation? You can rent bicycles from our hotel and explore the best places in Mount Abu!'
    },
    {
        heading: 'Trek a while',
        text: "Mount Abu offers a number of trekking-hiking trails for nature lovers. Toad-Rock Trek, Bailey's Walk, Trevors Tank Trail, Plummy Trek, Craigs Trails are a few to name. Indulge in an array of adventure activities with your friends like Rock climbing, Zorbing, Ziplining, Rappelling, Spelunking, Flying Fox to name a few."
    },
    {
        heading: 'Nakki Lake',
        text: 'Just 1 kilometer away from Hotel Hilltone, Nakki Jheel is the first man-made lake in India with a depth of around 110 meters. Spend your evening boating, aqua zorbing and savoring mouth-watering food that Mount Abu’s streets are famous for.'
    },
    {
        heading: 'Guru Shikhar',
        text: 'Located at 16 Kilometers from Hotel Hilltone, Guru Shikhar, a peak at an altitude of 5,676 feet, is the highest peak in Rajasthan and one of the best places to visit in Mount Abu.'
    },
    {
        heading: 'Dilwara Temple',
        text: 'Located at 3 Kilometers from Hotel Hilltone, Dilwara Temple is one of the finest Jain temples known world over for its phenomenal design and radiant marble stone carvings.'
    },
    {
        heading: 'Brahma Kumari International Headquarters',
        text: "The World Head Quarters of Brahma Kumaris is located at 1.5 Kilometers from Hotel Hilltone. Peace Park, Shanti Van, Universal Peace Hall are few of the spiritual establishments of the Brahma Kumaris in Mount Abu."
    },
    {
        heading: 'Sunset point',
        text: 'Located at 3 Kilometers from Hotel Hilltone, the Sunset Point gives a scenic perspective of the setting sun. The beautiful slopes, quiet encompassing, and the charming atmosphere makes it one of the most loved spot for the travelers.'
    },
    {
        heading: 'Trevors Tank',
        text: 'Located at 3 Kilometers from Hotel Hilltone, the tank offers pleasant landscape and is a prominent excursion destination for voyagers.'
    },
    {
        heading: 'Achalgarh Fort & Achaleshwar Mahadev Temple',
        text: 'Located at 6 Kilometers from Hotel Hilltone, Achaleshwar Mahadev Temple is famous for its natural occurring shiva linga, and is one of the most oldest temples.'
    },
];

export { InHouseCardsContent, ArounTheCityCardsContent };