import React from 'react';

import GeneralSuite from '../../../components/GeneralSuite';
import img1 from '../../../images/Rooms/luxuryroom/1.jpg';
import img2 from '../../../images/Rooms/luxuryroom/2.jpg';

import FancyButton from '../../../components/FancyButton';

const Luxury = (props) => {
   return (
      <GeneralSuite
         Heading={"Luxury Rooms"}
         Description={"Room Description"}
         width={"90px"}
         SupportImages={[img1, img2]}
         DescriptionContent={() => <React.Fragment>
            <div style={{fontSize: '1.4rem', lineHeight: '2rem'}}>
               Exuding elegance, our 300 sq ft Luxury Rooms are gracefully and
               sustainably designed, complete with high-end indulgences,
               contemporary furnishings and modern amenities, creating a truly
               unique experience.
            </div> <br />
            <div style={{fontSize: '1.4rem', lineHeight: '2rem'}}>
               To enable guests to relax in their own private space, these rooms
               have a balcony with unrestricted pool views.
            </div>
            <div
               style={{ display: 'flex', marginTop: '2rem' }}
            >
                  <a href= "https://bookings.hotelhilltone.com/?propertyId=8826">
              <FancyButton style={{margin: '1rem 0'}} text="Book a Stay" />
              </a>
            </div>
         </React.Fragment>}
         roomType={"luxury"}
         backFlag={true}
         {...props}
      />
   );
}

export default Luxury;