import React from 'react';
import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import Footer from '../../components/Footer';
import theme from '../../utils/theme';
import TextWithImage from '../../components/TextWithImage';
import LandingContent from '../../components/LandingContent';
import img1 from '../../images/Dining/mulberry/1.jpg';
import img2 from '../../images/Dining/mulberry/2.jpg';
import img3 from '../../images/Dining/mulberry/3.jpg';
import img4 from '../../images/Dining/mulberry/4.jpg';
import img5 from '../../images/Dining/mulberry/5.jpg';
import img6 from '../../images/Dining/mulberry/6.jpg';
import img7 from '../../images/Dining/mulberry/7.jpg';

import img8 from '../../images/Dining/risa/1.jpg';
import img9 from '../../images/Dining/risa/2.jpg';
import img10 from '../../images/Dining/risa/3.jpg';
import img11 from '../../images/Dining/risa/4.jpg';
import img12 from '../../images/Dining/risa/4.jpg';

import imgN from '../../images/Dining/DINING 2.png';

// import img2 from '../../images/Dining/Risa Bar & Cafe 2.png';
import bottomImg1 from '../../images/media/Jaipur architecture fest award 1.1.png';
import bottomImg2 from '../../images/media/Jaipur architecture fest 2019 award 1 2.1.png';
import bottomImg3 from '../../images/media/Jaipur architecture fest 2019 award 1.1.png';
import bottomImg4 from '../../images/media/Risa coverage in Surfaces Magazine.png';
import mul from '../../images/ads/mul.png';
import risa from '../../images/ads/logorisa.png';
const menuMulberry = "https://hotelhilltone.com/menu/menu1-1.pdf";
const menuRisaBar = "https://hotelhilltone.com/menu/menu1-2.pdf";
const menuInRoom = "https://hotelhilltone.com/menu/menu1-3.pdf";

const Dining = (props) => {
  const description1 = () => (
    <div>
      <Heading>Exquisite Gourmet Dining</Heading>
      <br />

      <p>
        The Mulberry Tree Restaurant offers fine family
        dining experience for every taste at any time of
        the day. lt offers a selection of Indian, Oriental,
        Italian and International cuisine.
      </p>
      <br />

      <p>
        The restaurant serves a well spread lavish buffet
        for breakfast with an interactive kitchen.
        For our more adventurous junior diners, we
        have a separate section of their favourite
        bites.
      </p>
      <br />

      <p>
        Lush garden of Mulberry Tree is open during
        good weather for a delightful al-fresco dining
        experience.
      </p>
      <br />

      <p>
        <span style={{ fontWeight: 'bold' }}>Hours of Operation: <br /> </span>
        Breakfast 7:30 am to 10:30 am <br />
        Lunch 12:00 pm to 3:00 pm <br />
        Snacks & Mini-meals 3:30 pm to 7:00 pm <br /> 
        Dinner 7:00 pm to 11:00 pm <br />
      </p>
      <br />

      <p>
        <span style={{ fontWeight: 'bold' }}>Seating Capacity:</span>
        <br />
        Indoor 64 seats <br />
        Outdoor 24 seats (weather permitting)
      </p>
      <br />

    </div>
  );

  const description2 = () => (
    <div>
      <Heading>Elevate Your Spirits</Heading>
      <br />

      <p>
        "RISA" celebrates the quintessential culture of
        social drinking. A place for connoisseurs, Risa is
        rendered with an artistic ambiance. The
        minimalist décor features natural materials
        palette enriched with the coolness and
        brilliance of turquoise upholstery, light and
        displays.
      </p>
      <br />

      <p>
        At Risa, we quench   thirst and satiate your
        appetite with an extensive line up of beverages
        including spirits, wines, cocktails and beers.
        An experienced mixologist helps the guests to
        create their own signature drink to enjoy whilst
        savoring delectable bites with friends and
        family at any time of the day.
      </p>
      <br />

      <p>
        For more information, kindly visit our <Link to="/special-offers">OFFERS </Link>
        page.
      </p>
      <br />

      <p>
        <span style={{ fontWeight: 'bold' }}>Hours of Operation:</span> <br />
        11:00 am to 11:00 pm
      </p>
      <br />

      <p>
        <span style={{ fontWeight: 'bold' }}>Seating Capacity: </span>
        40 Seats
      </p>
    </div>
  );

  const description3 = () => (
    <div>
      <p>
        For your comfort and convenience, we are pleased to provide 
        services of In Room Dining to you round the clock.
      </p>
      <br />

      <p>
        <span style={{ fontWeight: 'bold' }}>Hours of Operation: </span>
        24 Hours
      </p>
    </div>
  );

  const bottomImages = () => (
    <BottomImageConatainer>
      <Modal dimmer={"blurring"} trigger={<StyledBottomImg src={bottomImg1} />} closeIcon>
        <Modal.Content>
          <StyledImage src={bottomImg1} />
        </Modal.Content>
      </Modal>
      <Modal dimmer={"blurring"} trigger={<StyledBottomImg src={bottomImg2} />} closeIcon>
        <Modal.Content>
          <StyledImage src={bottomImg2} />
        </Modal.Content>
      </Modal>
      <Modal dimmer={"blurring"} trigger={<StyledBottomImg src={bottomImg3} />} closeIcon>
        <Modal.Content>
          <StyledImage src={bottomImg3} />
        </Modal.Content>
      </Modal>
      <Modal dimmer={"blurring"} trigger={<StyledBottomImg src={bottomImg4} />} closeIcon>
        <Modal.Content>
          <StyledImage src={bottomImg4} />
        </Modal.Content>
      </Modal>
    </BottomImageConatainer>
  );

  return (
    <Container>
      <LandingContent images={[img1, img8, img2, img9, img3, img10]} />

      <StyledContent>
        <TextWithImage
          DescriptionContent={description1} 
          Heading={"Mulberry Tree Restaurant"}
          Color={'#59002d'}
          SupportImages={[img1, img2, img3, img4, img5, img6, img7]}       
          menuLink={menuMulberry}
          width={"150px"}
          imgLogo = {mul}
          imgsize = {"30%"}
          marTop ={"39%"}
          {...props}
        />
        <TextWithImage
          DescriptionContent={description2} 
          Heading={"Risa Bar & Cafe"}
          Color={'#0c6a75'}
          SupportImages={[img8, img9, img10, img11, img12]}
          width={"150px"}
          menuLink={menuRisaBar}
          bottomImages={bottomImages}
          imgLogo = {risa}
          imgsize = {"15%"}
          marTop ={"45%"}
          {...props}
        />
        <TextWithImage
          DescriptionContent={description3} 
          Heading={"In-Room Dining"}
          SupportImages={imgN}
          menuLink={menuInRoom}
          width={"150px"}
          marTop ={"39%"}
          {...props}
        />
      </StyledContent>
      <Footer />
    </Container>
  );
}

export default Dining;

const Heading = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  font-family: 'OFL-Sorts';
  font-style: italic;
  margin-top: 4rem;
`;

const Container = styled.div`
width: 100%;
color: ${theme.primary};
`;

const StyledContent = styled.div`
position: relative;
padding-top: 0.5rem;
`;

const BottomImageConatainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem;
`;

const StyledBottomImg = styled.img`
  height: 100px;
  cursor: pointer;
`;

const StyledImage = styled.img`
  width: 100%;
`;