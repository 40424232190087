import React from 'react';
import styled from 'styled-components';

import LandingContent from '../../components/LandingContent';
import colors from '../../utils/theme';
import ImageWithSideText from './components/ImageWithSideText';
import { Modal } from 'semantic-ui-react';
import Header from '../../components/Header';
import Comment from './comments';
import commentImage from '../../images/home/R.Suite.png';
import img1 from '../../images/Special Offers/HOTEL PICS (17).png';
import img2 from '../../images/Special Offers/EDUCATIONAL EXCURSIONS.png';
import img3 from '../../images/Special Offers/CORPORATE OFFSITES.png';

import img4 from '../../images/Special Offers/1.jpg';
import img5 from '../../images/Special Offers/2.jpg';
import img6 from '../../images/Special Offers/3.jpg';
import img7 from '../../images/Special Offers/4.jpg';
import img8 from '../../images/Special Offers/5.jpg';
import img9 from '../../images/Special Offers/6.jpg';

import Footer from '../../components/Footer';

// const icon1 = require('../../images/media/IMG_0383.png');
// const icon2 = require('../../images/media/IMG_0384 (2).png');
const icon1 = require('../../images/media/img.png');
const icon2 = require('../../images/media/img2.png');
const icon3 = require('../../images/media/img3.jpg');

const SpecialOffers = () => {
  return (
    <React.Fragment>
      <LandingContent images={[img4, img5, img6, img7, img8, img9]} />
      <GuestSpeech>
          <Container>
            <Heading>What our Guests are saying...</Heading>

            <Comment />
          </Container>
        </GuestSpeech>
      
      <Footer />
    </React.Fragment>
  )
}

export default SpecialOffers;



const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1200px;
  & > div > div > div > ul > li {
    background: #0000 !important;
  }
`;

const SmallImages = styled.div`
  display: flex;
  margin-left: 10vw;
  margin-top: 20px;
`;

const Image = styled.img`
  max-height: 100px;
  margin-right: 2rem;
  cursor: pointer;
  &:hover {
    filter: blur(1px);
  }
`;

const StyledModalImage = styled.img`
  width: 100%;
`;

const GuestSpeech = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  opacity: .8;
  color: #fff;
  background-image: linear-gradient( rgba(0,0,0,.6),rgba(0,0,0,.6)), url(${commentImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-width: 100%;
  background-position: center;
  background-attachment: local;
`;

const Heading = styled.div`
  font-family: OFL Sorts Mill Goudy TT;
  font-style: italic;
  font-weight: 500;
  font-size: 48px;
  line-height: 73px;
  text-align: center;
  color: #FFFFFF;
`;