import React from 'react';
import styled from 'styled-components';

import theme from '../../utils/theme';

function FancyButton(props) {
  return (
    <StyledButton {...props}>{props.text}</StyledButton>
  )
}

export default FancyButton;

const StyledButton = styled.button`
  font-family: 'OFL-Sorts';
  height: ${({height}) => height ?  height : '50px'};
  min-width:40px;
  min-height: 36px;
  border: none;
  user-select: none;
  background: ${theme.primary};
  background: ${props => props.color};
  color: white;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 1rem 0.7rem;
  cursor: pointer;
  font-size: ${props => props.height ? '1.6rem' : '1.8rem'};
  font-style: italic;
  `;
  // font-style: ${props => props.height ? 'italic' : 'normal'}