import React from 'react';
import styled from 'styled-components';

const ImageWithSideText = (props) => {
  const { imgDirection, heading, text, img } = props;
  return (
    <Container {...props}>
      <Image src={img} {...props} />
      <TextWithFilter imgDirection={imgDirection}>
        <InnerContainer>
          {imgDirection === 'left' ? (
            <React.Fragment>
              <Filter />
              <Section />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Section />
              <Filter />
            </React.Fragment>
          )}
          <TextContent>
            <Heading>{heading}</Heading>
            <Text>{text}</Text>
          </TextContent>
        </InnerContainer>
      </TextWithFilter>
    </Container>
  );
};

export default ImageWithSideText;

const Container = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  margin: 2rem 10vw;
  min-height: 250px;
  margin-bottom: ${({iconsAvailable}) => iconsAvailable ? '0' : '2rem'}
  justify-content: ${p => p.imgDirection === 'right' ? 'flex-end' : 'flex-start'};
  @media only screen and (max-width: 800px) {
    padding: 0;
    margin: 0;
  }
`;

const Image = styled.img`
  width: 70%;
  min-height: 100%;
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;

const TextWithFilter = styled.div`
  display: flex;
  position: absolute;
  top: 10%;
  bottom: 10%;
  width: 50%;
  ${({imgDirection}) => imgDirection === 'right' ? 'left: 0;' : 'right: 0;'}
  display: flex;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  
  @media only screen and (max-width: 800px) {
    bottom: 10%;
    left: 10%;
    width: 100%;
    top: 90%;
    ${({imgDirection}) => imgDirection === 'right' ? 'left: 0;' : 'right: 0;'}
    display: flex;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

const Filter = styled.div`
  width: 40%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  @media only screen and (max-width: 800px) {
    width: 100%;
    min-height: 60px;
  }
`;

const Section = styled.div`
  width: 60%;
  height: 100%;
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;

const TextContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    padding-top: 10%;
  }
`;

const Heading = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin-top: 2rem;
  line-height: 2rem;
  @media only screen and (max-width: 1100px) {
    font-size: 2rem;
  }
  @media only screen and (max-width: 800px) {
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

const Text = styled.div`
  display: flex;
  flex: 1;
  height: calc(100% - 6rem);
  justify-content: center;
  align-items: center;
 
  font-size: 1.4rem;
  @media only screen and (max-width: 1000px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 800px) {
    font-size: 0.8rem;
    margin-top:20px;
    line-height: 1em;

  }
`;

