import React from 'react';

import GeneralSuite from '../../../components/GeneralSuite';

import img1 from '../../../images/Rooms/caveroom/1.jpg';
import img2 from '../../../images/Rooms/caveroom/2.jpg';
import img3 from '../../../images/Rooms/caveroom/3.jpg';
import FancyButton from '../../../components/FancyButton';

const Rock = (props) => {
  return (
    <GeneralSuite
      Heading={"Rock Suite"}
      Description={"Room Description"}
      width={"100px"}
      SupportImages={[img1, img2, img3]}
      DescriptionContent={() => <React.Fragment>
        <div style={{ fontSize: '1.4rem', lineHeight: '2rem' }}>
          Inspired by the majestic Ajanta-Ellora Caves, our 360 sq ft Rock
          Suite allures you with its exclusive architecture and elegant
          décor.
        </div> <br />
        <div style={{ fontSize: '1.4rem', lineHeight: '2rem' }}>
          Offering an off-the-grid experience to nature and outdoor
          lovers, the atmosphere of the room blends seamlessly with the
          cave architecture.
        </div> <br />
        <div style={{ fontSize: '1.4rem', lineHeight: '2rem' }}>
          To top it on, there is a private sit-out area and semi-private
          garden, offering a spectacular view of the clear blue skies.
        </div>
        <div
          style={{ display: 'flex', marginTop: '2rem' }}
        >
          <a href="https://bookings.hotelhilltone.com/?propertyId=8826">
            <FancyButton style={{ margin: '1rem 0' }} text="Book a Stay" />
          </a>
        </div>
      </React.Fragment>}
      backFlag={true}
      roomType={"rock"}
      {...props}
    />
  );
}

export default Rock;