import React from 'react';

import GeneralSuite from '../../../components/GeneralSuite';

import img1 from '../../../images/Rooms/executivesuite/1.jpg';
import img2 from '../../../images/Rooms/executivesuite/2.jpg';
import img3 from '../../../images/Rooms/executivesuite/3.jpg';
import img4 from '../../../images/Rooms/executivesuite/4.jpg';
import img5 from '../../../images/Rooms/executivesuite/5.jpg';
import img6 from '../../../images/Rooms/executivesuite/6.jpg';
import img7 from '../../../images/Rooms/executivesuite/7.jpg';
import img8 from '../../../images/Rooms/executivesuite/8.jpg';
import img9 from '../../../images/Rooms/executivesuite/9.jpg';
import img10 from '../../../images/Rooms/executivesuite/10.jpg';
import img11 from '../../../images/Rooms/executivesuite/11.jpg';

import FancyButton from '../../../components/FancyButton';

const Executive = (props) => {
   return (
      <GeneralSuite
         Heading={"Suites"}
         Description={"Room Description"}
         width={"150px"}
         SupportImages={[img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11]}
         DescriptionContent={() => <React.Fragment>
            <div style={{fontSize: '1.4rem', lineHeight: '2rem'}}>
               Our 430 sq ft, lavishly spacious Suites come with a
               separate living area, sofa sitting and wooden flooring. 
            </div> <br />
            <div style={{fontSize: '1.4rem', lineHeight: '1.5rem'}}>
               Located on level one and level two, our suites are
               decorated with elegant furnishings and offer garden facing
               panoramic views.
            </div>
            <div
               style={{ display: 'flex', marginTop: '2rem' }}
            > 
               <a href= "https://bookings.hotelhilltone.com/?propertyId=8826">
                  <FancyButton style={{margin: '1rem 0'}} text="Book a Stay" />
               </a>
            </div>
         </React.Fragment>}
         roomType={"executive"}
         backFlag={true}
         {...props}
      />
   );
}

export default Executive;