import React from 'react';
import { Route, HashRouter as Router } from 'react-router-dom';

import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import { Deluxe, Executive, Luxury, Premier, Rock, Superior, Villa } from './pages/Suites';
import Dining from './pages/Dining';
import Rooms from './pages/Rooms';
import Meetings from './pages/Meetings';
import Experiences from './pages/Experiences';
import Contact from './pages/Contact';
import Happening from './pages/Happening';
import SpecialOffers from './pages/SpecialOffers/';
import Wellness from './pages/Wellness';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Route exact path="/" component={Home} />
      <Route path="/about" component={AboutUs} />
      <Route path="/deluxe" component={Deluxe} />
      <Route path="/executive" component={Executive} />
      <Route path="/luxury" component={Luxury} />
      <Route path="/premier" component={Premier} />
      <Route path="/rock" component={Rock} />
      <Route path="/superior" component={Superior} />
      <Route path="/villa" component={Villa} />
      <Route path="/dining" component={Dining} />
      <Route path="/rooms" component={Rooms} />
      <Route path="/meetings-and-events" component={Meetings} />
      <Route path="/experiences" component={Experiences} />
      <Route path="/contact" component={Contact} />
      <Route path="/special-offers" component={SpecialOffers} />
      <Route path="/wellness" component={Wellness} />
      <Route path="/happening" component={Happening} />
    </Router>
  );
}

export default App;
