import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import theme from '../../utils/theme';

const LargeTextWithLine = (props) => {
  const {text, width, style: styling } = props;
  return (
    <LargeText {...props} style={{...styling}}>
      {text}
      <SmallLine width={width} {...props} />
    </LargeText>
  );
}

export default LargeTextWithLine;

const LargeText = styled.div`
  font-family: 'OFL-Sorts';
  font-size: ${({fontSize}) => fontSize || '40px'};
  font-weight: bold;
  color: ${theme.primary};
  color: ${props => props.color};
  margin: 2.5rem;
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  @media only screen and (max-width: 1100px) {
    padding-bottom: 4rem;
  }
  @media only screen and (max-width: 800px) {
    font-size: 30px;
    margin: 0;
    margin-left: 1.5rem;
    padding: 0;
  }
`;

const SmallLine = styled.div`
  margin-top: ${({marginTop}) => marginTop || '2rem'};
  border-bottom: 2px solid ${theme.primary};
  border-bottom: 2px solid ${props => props.color};
  width: ${props => props.width};
  @media only screen and (max-width: 800px) {
    margin-top: 1rem;    
  }
`;

LargeTextWithLine.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};