const ROOM_FEATRUES = [
  'Rooms with Sit-outs or Balcony',
  'Garden facing or Pool facing rooms',
  'Heating/ Air-Conditioning',
  'Mini Bar',
  'Electronic Safe',
  'Tea & Coffee Maker',
  'Rooms with Swimming Pool facing Balcony',
  'Private Sit-Out or Semi-Private Garden',
  'Garden facing rooms',
  'Room with garden facing panoramic view',
  'Private Jacuzzi',
  'Balcony overlooking Swimming Pool',
  'Separate living area with 43’’ LED Television'
];

const BED_AND_BATH = [
  'King Size Bed',
  'Cosy Duvet & Pillows',
  'Italian Marble Washroom equipped with Head & Body Shower and luxurious amenities',
  'Bathroom Slippers',
  'Marble Washroom equipped with Bathtub or Shower Cubicle and luxurious amenities',
  'Cosy Duvet & Pillows',
  'Bathrobe & Bathroom Slippers',
  'Washroom equipped with Bathtub or Shower Panel and luxurious amenities'
];

const TECHNOLOGY= [
  '43’’ LED Television with Satellite connection',
  'Telephone',
];

const SERVICES = [
  '24 Hour Room Service',
  'Wireless Internet Connectivity',
  'Reading Lights',
  'Laundry service',
  'Ironing Board (on request)',
  'Hair dryer (on request)',
  'Newspaper (on request)',
  'Morning cleaning & evening turndown',
  
];

const Amenities = {
  premier: {
    features: [8,2,3,4,5],
    bath: [0,1,2,3],
    tech: [0,1],
  },
  superior: {
    features: [8,2,4,5],
    bath: [0,7,3],
    tech: [0,1],
  },
  deluxe: {
    features: [0,1,2,3,4,5],
    bath: [0,1,4,3],
    tech: [0,1],
  },
  luxury: {
    features: [6,2,3,4,5],
    bath: [0,1,2,3],
    tech: [0,1],
  },
  rock: {
    features: [7,8,2,3,4,5],
    bath: [0,1,4,6],
    tech: [0,1],
  },
  executive: {
    features: [12,9,2,3,4,5],
    bath: [0,1,7,3],
    tech: [0,1],
  },
  villas: {
    features: [10,11,2,3,4,5],
    bath: [0,1,2,3],
    tech: [0,1],
  },
};

export { Amenities, ROOM_FEATRUES, BED_AND_BATH, TECHNOLOGY, SERVICES };