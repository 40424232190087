const HallData1 = {
  parishadHall1: {
    text: `We at Hotel Hilltone, understand that our corporate travelers deserve exclusive attention, matchless
      professionalism and panache. Our tastefully designed Parishad Hall, located on the first floor, with an
      area of 2100 sq feet, can host upto 200 people for corporate and social events. The pillar-less hall
      perfectly complements peaceful surroundings of the mountains. Good acoustic as well as great
      illumination provides delegates the most congenial atmosphere to make their events a success. A
      private pre function area outside, provides the perfect setting for breaks and for mingling.`,
    heading: 'Parishad Hall I',
    dimensions: {
      main: '70 X 30',
      theatre: 220,
      classroom: 90,
      uShape: 50,
      sitDown: 80,
      cockTails: '80-100'
    },
    facilities: `Mike (Lapel microphone, handheld cordless microphone), podium, screen (8 ft x 8 ft), LCD
      projector, Internet, backdrop projection, LED screens, special stage setups and more.`
  },
  parishadHall2: {
    text: ` This serves as an elegant space to hold intimate gatherings. With a separate access as well as connected
      through Parishad Hall I, with area of 750 sq. feet, it can host upto 70 people.`,
    heading: 'Parishad Hall II',
    dimensions: {
      main: '44 X 18',
      theatre: 70,
      classroom: 40,
      uShape: 20,
      sitDown: 30,
      cockTails: 30
    },
    facilities: `Mike (Lapel microphone, handheld cordless microphone), podium, screen (8 ft x 8 ft), LCD projector,
      Internet, backdrop projection, LED screens, special stage setups and more.`
  },
  boardRoom: {
    text: `Including a sense of formality, our Board Room, with its rich dark wooden paneling, is ideal for hosting all-day
      executive conclaves, presentations and group discussions.`,
    text2: 'In an area of 400sq feet, it can accommodate upto 30 people.',
    heading: 'Board Room',
    dimensions: {
      main: '25 X 17',
      theatre: 30,
      classroom: 20,
      uShape: 20,
      sitDown: 25,
      cockTails: 25
    },
    facilities: `Mike (Lapel microphone, handheld cordless microphone), podium, screen (8 ft x 8 ft), LCD projector,
      backdrop projection, and more.`
  }
};

const HallData2 = {
  mainLawn: {
    heading: 'Main Lawn',
    boldText: `Celebrate life’s special events at Hotel Hilltone.`,
    text: `Located right in the heart of the hotel, our main lawn provides a picturesque background to your wedding
      events. The grandeur of the space can accommodate personal preferences in terms of décor, planning,
      ambiance and delectable menus.

      It is a venue that can impress and awe nearly 400 guests at one event.
      `
  },
  poolsideLawn: {
    heading: 'Poolside Lawn',
    boldText: '',
    text: `The green environs and cool breeze along with turquoise blue waters of our swimming pool, make a unique
    venue for brunches, celebrations, theme evenings, hi-tea, barbeque dinners and more.
    
    It can accommodate up to 150 guests.`
  }
}

export {HallData1, HallData2};