import React from 'react';
import styled from 'styled-components';

import GeneralSuite from '../../../components/GeneralSuite';

import img1 from '../../../images/Rooms/premier-room/1.jpeg';
import img2 from '../../../images/Rooms/premier-room/2.jpeg';
import img3 from '../../../images/Rooms/premier-room/3.jpg';
import img4 from '../../../images/Rooms/premier-room/4.jpg';
import img5 from '../../../images/Rooms/premier-room/5.jpg';
import img6 from '../../../images/Rooms/premier-room/6.jpg';
import img7 from '../../../images/Rooms/premier-room/7.jpg';
import img8 from '../../../images/Rooms/premier-room/8.jpg';

import FancyButton from '../../../components/FancyButton';

const Premier = (props) => {
   return (
      <GeneralSuite
         Heading={"Premier Rooms"}
         width={"100px"}
         SupportImages={[img1, img2, img3, img4, img5, img6, img7, img8,]}
         Description={() => <React.Fragment>
            <StyledPara style={{fontSize: '1.4rem', lineHeight: '2rem'}}>Views of our landscaped gardens and the Aravalli Range are
            best enjoyed from the sit-out area outside our Premier Rooms
            on the ground floor.
            The cushioned bay window seats let you bring the outdoors in
            through the large French windows in the rooms on level one
            and two.</StyledPara> 
            <br />
            <p style={{fontSize: '1.4rem', lineHeight: '2rem'}}>In our Green House Premier Room, every detail has been
            considered in providing an exceptional accommodation
            consisting of two inter-connecting rooms which can be used
            individually or jointly.</p>
            <div
               style={{ display: 'flex', marginTop: '2rem' }}
            >
               <a href= "https://bookings.hotelhilltone.com/?propertyId=8826">
                  <FancyButton style={{margin: '1rem 0'}} text="Book a Stay" />
               </a>
            </div>
         </React.Fragment>}
         backFlag={true}
         roomType={"premier"}
         {...props}
      />
   );
}

export default Premier;

const StyledPara = styled.div`
   @media only screen and (max-width: 800px) {
      margin-top: 4rem;
   }
`;