const QA = [
  {
    question: 'Where are you located?',
    answer: `We are centrally located on the main arterial road of Mount Abu, only a kilometer away from the famous Nakki Lake, en-route to
    which there are lots of eating joints and shopping places.`
  },
  {
    question: `How do I reach Mount Abu?`,
    answer: [
      'Mount Abu can be reached through:',
      `Air: The nearest domestic airport is located at Udaipur at a distance of 210 kilometers and it
      takes about 2.5 hours of scenic drive through the Aravalli hills. The nearest international airport islocated at Ahmedabad which is 225 kilometers away and it takes about 4.5 hours through NH 27.`,
      `Railway: AbuRoad is the nearest railway station just 28 kilometersfrom the main city.This station connects Mount Abu to the major railheads.`,
      `Road: Daily direct bus services to the hill station are available from most cities. Depending on budget, passengers can avail AC, deluxe or semi-deluxe buses.`,
    ]
  },
  // {
  //   question: `What is the Check-in and Check-out time?`,
  //   answer: [
  //     `Check-in: 1:00 pm`,
  //     `Check-out: 11:00 am`,
  //     `Early check-in or late check-out is subject to availability and applicable charges.`
  //   ],
  //   noLineHeight: true,
  // },
  // {
  //   question: `What is the Hotel’s Cancellation Policy?`,
  //   answer: [`Cancellation requests can be made to reservations@hotelhilltone and shall be processed as per the Hotel’s cancellation policy mentioned below:`,
  //     `- 100% refund if cancellation is affected before 15 days of the check-in date.`,
  //     `- 75% refund if cancellation is affected between 07 to 14 days of the check-in date.`,
  //     `- 50% refund if cancellation is affected between 04 to 06 days of the check-in date.`,
  //     `- No refund if cancellation is affected within 03 days of the check in date.`,
  //     `- In case of no show (NON-ARRIVAL) booking,full amount as retention shall be charged.`,
  //     `- Group Cancellation Policy: Please contact the Hotel for group cancellation policy.`
  //   ],
  // },
  {
    question: `What kind of food will I find at Mulberry Tree Restaurant?`,
    answer: `You will find Indian, Oriental and few popular International dishes at the Mulberry Tree Restaurant.`
  },
  // {
  //   question: `Are children allowed?`,
  //   answer: `Yes, children are allowed. Children below the age of 5 years stay complimentary, children between 6 to 11 years are chargeable and above 12 years are charged as an adult.`
  // },
  // {
  //   question: `How many people can stay in a Room?`,
  //   answer: [
  //     `A Room can accommodate either 3 adults or 2 adults and 2 children below the age of 11 years.`,
  //     `However, our Suites can accommodate up to 4 adults or 3 adults and 2 children below the age of 11 years.`
  //   ],
  // },
  {
    question: `How many rooms can I book in one reservation?`,
    answer: [
        `A maximum of 5 rooms can be booked in any one reservation through our website.`,
        `For group reservations, kindly write to us at reservations@hotelhilltone.com or corporate@hotelhilltone.com or call us at +91 9414154888 or +91 9460654888`
      ],
    },
  {
    question: `Do you have parking space, car cleaning & driver accommodation?`,
    answer: [`Yes, our hotel has ample parking space for cars. However large buses have to be parked in a parking lot which is paid.`,
      `Hotel does not provide car cleaning facilty.`,
      
    ]
  },
  // {
  //   question: `How can I cancel or change my booking?`,
  //   answer: `You can write to us for any amendment or cancellation to you reservation. 
  //   The same shall be considered in accordance to the Hotel’s amendment or cancellation policy.`
  // },
  {
    question: `What are the preferred modes of payment?`,
    answer: [
      `The hotel accepts payment through cash, credit card, debit card, bank transfer and demand draft. Personal cheques are not accepted as per hotel policy.`,
      `To have a guaranteed reservation, atleast 50% advance has to be deposited at the time of making the reservation and balance at the time of check-in.`,
      `During peak seasons, 100% non-refundable deposit is required for getting a confirmed reservation.`
    ]
  },
  {
    question: `Is it safe to fill in my credit card details? How secure is your website?`,
    answer: `Your credit card information is sent to us through a secure server. We use the latest, most secure technology available, which encrypts all your personal and credit card details. 
    The encryption method used is the industry standard “Secure Socket Layer” (SSL) Technology.`
  }
];

export default QA;