import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';

import logoImg from '../../images/hilltone1.png';
import defaultImg1 from '../../images/landing_img1.jpg';
import defaultImg2 from '../../images/about_main_img.png';
import defaultImg3 from '../../images/dining_home.png';
import FancyButton from '../FancyButton';

let sliderTimer;

class LandingContent extends React.Component {
  static propTypes = {
    images: PropTypes.array.isRequired,
    isHome: PropTypes.bool,
  }

  static defaultProps = {
    images: [defaultImg1, defaultImg2, defaultImg3, defaultImg1, defaultImg2],
    isHome: false,
  }

  state = {
    index: 1,
    imagesToRender: null,
    playing: true,
  }

  componentDidMount() {
    this.setSliderTimer();
  }

  setSliderTimer = () => {
    sliderTimer = setInterval(() => this.renderNextImage(), 5000);
  }

  componentWillUnmount() {
    clearInterval(sliderTimer);
  }

  renderNextImage() {
    const { index, imagesToRender } = this.state;
    const { images } = this.props;

    const lastIndex = (imagesToRender ? imagesToRender.length : images.length) - 1;
    let newImages;

    if(index >= lastIndex - images.length) {
      // console.log(imagesToRender);
      const firstArray = imagesToRender ? imagesToRender : images;
      newImages = [...firstArray, ...images];
      this.setState({ index: index + 1, imagesToRender: newImages })
    } else if(!imagesToRender) {
      this.setState({ index: index + 1, imagesToRender: images })
    } else {
      this.setState({ index: index + 1 });
    }
  }

  renderPreviousImage = () => {
    const {index} = this.state;
    if(index > 1) {
      this.setState({index: index - 1});
    }
  }

  render() {
    const { index, imagesToRender } = this.state;
    let sliderImages = imagesToRender ? imagesToRender : this.props.images;
    sliderImages = [sliderImages[0], ...sliderImages];  // just to show first image first;

    // Carousel has following default features
    // 1. Auto rotate right
    // 2. Circular scrolling of images
    // 3. central Image will have full height
    // 4. Other image will be 95% height
    return (
      <StyledContent {...this.props}>
        
        <ImageContainer
          // onClick={this.playAndPause}
        >
          <BtnLeft
            onClick={e => this.renderPreviousImage()}
            text={<Arrow></Arrow>}
            index={index}
          />
          {
            sliderImages.map((image, i) => <Image
              src={image}
              style={{
                transform: `translate(-${index * 100}%, 0)`,
                margin: index === i ? '0' : '5% 0',
                filter: index === i ? 'none' : 'grayscale(100%)',
                zIndex: index === i ? '1' : '0',
              }}
              key={'carousel-c' + i}
            />)
          }
          <BtnLeft
            onClick={e => this.renderNextImage()}
            right={true}
            text={<Arrow right={true}></Arrow>}
          />
        </ImageContainer>
        <HeaderContainer>
          <Header {...this.props} />
        </HeaderContainer>
      </StyledContent>
    );
  }

  playAndPause = (e) => {
    const { playing } = this.state;
    playing ? clearInterval(sliderTimer) : this.setSliderTimer();
    this.setState({ playing: !playing });
  }
}

export default LandingContent;

const Image = styled.img`
  min-width: 90%;
  transition: 0.6s ease-in-out;
  @media only screen and (max-width: 800px) {
    min-width: 100%;
    width: auto;
  }
`;

const Logo = styled.div`
  position: absolute;
  display: flex;
  width: 100vw;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 10%;
  z-index: 5;
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 10%;
  position: relative;
  @media only screen and (max-width: 800px) {
    padding-left: 0;
  }
`;

const StyledContent = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;
`;

const HeaderContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 5;
`;

const BtnLeft = styled(FancyButton)`
  position: absolute;
  z-index: 100;
  top: 42.5%;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  ${({right}) => right ? 'right: 1.25%;' : 'left: 1.25%;'};
  opacity: ${({index}) => index && index < 2 ? '0.5' : '1'};
  background: rgba(126, 118, 71, 0.8);

  // cursor: ${({index}) => index && index < 2 ? 'not-allowed' : 'pointer'};`;

const Arrow = styled.i`
  border: solid #000;
  border-width: 0 2px 2px 0;
  padding: 5px;
  transform: ${({right}) => right
    ? 'rotate(-45deg); margin-left: -5px;'
    : 'rotate(135deg); margin-left: 5px;'
  };
`;