import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

class MenuIcon extends React.Component {
  static propTypes = {
    toggleDrawer: PropTypes.func.isRequired,
    showCross: PropTypes.bool,
  }

  static defaultProps = {
    showCross: false,
  }

  render() {
    const {white} = this.props;
    return (
      <StyledLineContainer onClick={() => this.props.toggleDrawer()}>
        <LineOne white={white} index={1} {...this.props} />
        <LineTwo white={white} index={2} {...this.props} />
        <LineOne white={white} index={3} style={{display: this.props.showCross ? 'none' : 'block'}} {...this.props}/>
      </StyledLineContainer>
    )
  }
}

export default MenuIcon;

const StyledLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 27px;
  cursor: pointer;
  margin-right: 1rem;
  position: relative;
`;

const LineOne = styled.div`
  width: 100%;
  height: 3px;
  background: ${({white}) => white ? 'white' : '#7E7647'};
  ${({showCross}) => showCross && 'background: #fff;'}
  margin: 2px;
  ${({showCross, index}) => showCross && `
    margin: 0;
    position: absolute;
    top: 0;
    ${
      index === 1 && `
      transform: rotate(45deg);
      transition: all 1s;
    `}
    }
  `}
`;

const LineTwo = styled.div`
  width: 100%;
  height: 3px;
  background: ${({white}) => white ? 'white' : '#7E7647'};
  ${({showCross}) => showCross && 'background: #fff;'}
  margin: 2px;
  ${({showCross, index}) => showCross && `
    margin: 0;
    ${index === 2 && `
      transform: rotate(135deg);
      transition: all 1s;
    `}
    }
  `}
`;
