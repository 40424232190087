import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import LandingContent from '../LandingContent';
import TextWithImage from '../TextWithImage';
import Footer from '../Footer';
import theme from '../../utils/theme';
import img from '../../images/landing_img1.jpg';
import AmenitiesAndServices from '../AmenitiesAndServices';

const GeneralSuite = (props) => {
  const { backFlag, Description, Heading, roomType, SupportImages } = props;
  return (
    <Container>
      <LandingContent images={SupportImages} />
      <StyledContent>
        {
          backFlag ? (
            <BackButton onClick={e => {
              props.history.length > 0 ? props.history.goBack() : props.history.push('/rooms');
            }}><Arrow /></BackButton>
          ) : ''
        }
        {
          Array.isArray(Heading) ? (
              Heading.map((text, i) => <TextWithImage
                {...props}
                DescriptionContent={Description && Description.length ? Description[i] : () => <div></div>} 
                Heading={text} 
                key={Math.random()*1000 + 'Dining'}
              />
            )
          ) : (
            <TextWithImage DescriptionContent={Description} {...props} />
          )
        }
      </StyledContent>
      {roomType ? <AmenitiesAndServices {...props} /> : ''}
      <Footer />
    </Container>
  );
}

GeneralSuite.propTypes = {
  MainImage: PropTypes.string,
  // Description: PropTypes.array, || string
  width: PropTypes.string,
  roomType: PropTypes.string,
}

GeneralSuite.defaultProps = {
  Description: [],
  roomType: null,
}

GeneralSuite.defaultProps = {
  MainImage: img,
  width: '100px',
};

export default GeneralSuite;

const Container = styled.div`
  width: 100%;
  color: ${theme.primary};
`;

const StyledContent = styled.div`
  position: relative;
  padding-top: 0.5rem;
`;

const BackButton = styled.button`
  position: absolute;
  border: none;
  width: 40px;
  height: 40px;
  background: rgba(126,118,71,.8);
  color: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  z-index: 5;
  left: 1rem;
  top: 2rem;
  margin-top: -1rem;
  cursor: pointer;
`;

const Arrow = styled.i`
  border: solid #fff;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: ${({right}) => right
    ? 'rotate(-45deg); margin-left: -2px;'
    : 'rotate(135deg); margin-left: 2px;'
  };
`;