import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';

class Comments extends React.Component {
  componentDidMount() {
    document.getElementsByClassName('control-prev')[0].style.opacity = 0;
    document.getElementsByClassName('control-next')[0].style.opacity = 0;
  }

  render() {
    return (
      <React.Fragment>
        <Carousel
          infiniteLoop
          showThumbs={false}
          autoPlay
          dynamicHeight
          stopOnHover
          emulateTouch
          swipeable
          interval={12000}
          transitionTime={700}
          showIndicators={false}
          showStatus={false}
        >
          <StyledQuote>
            <q style={{ fontSize: '3rem', fontWeight: 'bold' }}>
              <Block>
                {/*<GuestSpeechHeading style={{ fontFamily: 'OFL-Sorts', fontStyle: 'italic' }}>
                  A really luxurious property to stay with family or friends
                    </GuestSpeechHeading> */}
                <GuestSpeechContent>
                  Well maintained property with close vicinity to Nakki Lake. One can take a walk from
                  the hotel to the market and reach the lake. The restaurant &quot;Mulberry&quot; serves excellent
                  food and the in-room dining is also good. The staff is courteous and makes your stay
                  pleasant. Nice clean rooms and a well maintained property.- MR. VISHAL VASU 
                    </GuestSpeechContent>
              </Block>
                <GuestSpeechContent></GuestSpeechContent>
              <Block style={{ padding: '0' }}>
                
                   {/* <GuestSpeechContent style={{ textAlign: 'center', fontWeight: 'bold', fontStyle: 'normal', fontSize: '1.7rem', lineHeight: '2' }}>Google</GuestSpeechContent> */}
              </Block>
            </q>
          </StyledQuote>
          <StyledQuote>
            <q style={{ fontSize: '3rem', fontWeight: 'bold' }}>

              <Block>
                <GuestSpeechHeading style={{ fontFamily: 'OFL-Sorts', fontStyle: 'italic' }}>
                 
                    </GuestSpeechHeading>
                <GuestSpeechContent>
                    Excellent property with luxurious rooms, great food and delightful service. Recently
                    spent a weekend there with extended family. Staff took excellent care of us from
                    bringing umbrellas to accommodating a vegan in the group. Complimentary breakfast
                    was very good, but go early as it gets busy after 8:30am.- MRS. PRIYA HARIANI
                    </GuestSpeechContent>
              </Block>
            </q>
          </StyledQuote>
          <StyledQuote>
            <q style={{ fontSize: '3rem', fontWeight: 'bold' }}>

              <Block>
                <GuestSpeechHeading style={{ fontFamily: 'OFL-Sorts', fontStyle: 'italic' }}>
                  
                    </GuestSpeechHeading>
                <GuestSpeechContent>
                  I would highly suggest anyone who is visiting Mount Abu to take a stay at this
                  beautiful property. From the property, to the staff everything is top notch. I would
                  highly appreciate hotel&#39;s Manager, receptionist and each and every staff member. The
                  best staff I ever had an encounter with.- MR. CHAYAN AJMERA
                  </GuestSpeechContent>
              </Block>
            </q>
          </StyledQuote>
          <StyledQuote>
            <q style={{ fontSize: '3rem', fontWeight: 'bold' }}>

              <Block>
                <GuestSpeechHeading style={{ fontFamily: 'OFL-Sorts', fontStyle: 'italic' }}>
                  
                    </GuestSpeechHeading>
                <GuestSpeechContent>
                  When one goes for holiday, one looks for comfort, amenities, hygienic stay, good
                  hospitality, good food, access to tourist places and pocket friendly stay. Hotel Hilltone
                  has that all. It is a beautiful resort spread over a vast area. From mountain-view to
                  cloud cover, from chirping birds to blooming flowers, from pool fun to restaurant time,
                  whatever you need, they got you covered. The owners of the resort personally look into
                  nitties and gritties as they are present at the Property full time. Thinking of visiting
                  Mount Abu, book Hilltone- MRS. SWATI GUPTA
                  </GuestSpeechContent>
              </Block>
            </q>
          </StyledQuote>
          <StyledQuote>
            <q style={{ fontSize: '3rem', fontWeight: 'bold' }}>

              <Block>
                <GuestSpeechHeading style={{ fontFamily: 'OFL-Sorts', fontStyle: 'italic' }}>
                  
                    </GuestSpeechHeading>
                <GuestSpeechContent>
                    Loved spending time with family here. Beautiful place, exceptionally clean, super
                    luxurious, uber-helpful people. Amar Singh @MulberryTree (the restaurant) is the best.
                    A special shout-out to the housekeeping staff for being so accommodating &amp; kind.-
                    MR. MARIO VAZ
                  </GuestSpeechContent>
              </Block>
            </q>
          </StyledQuote>

          <StyledQuote>
            <q style={{ fontSize: '3rem', fontWeight: 'bold' }}>

              <Block>
                <GuestSpeechHeading style={{ fontFamily: 'OFL-Sorts', fontStyle: 'italic' }}>
                  
                    </GuestSpeechHeading>
                <GuestSpeechContent>
                  Booked Hilltone for birthday celebrations over a weekend. We utterly enjoyed our stay
                  with them (the Ellora Cave room is outstanding). Mulberry restaurant, Risa cafe,
                  Garden area, Bakery, pool you just name it, they have it all. Professional and helpful
                  staff with beautiful ambience all around. Keep up the great work :)- MS. RHYTHM
                  BATHLA        
              </GuestSpeechContent>
              </Block>
            </q>
          </StyledQuote>
          <StyledQuote>
            <q style={{ fontSize: '3rem', fontWeight: 'bold' }}>

              <Block>
                <GuestSpeechHeading style={{ fontFamily: 'OFL-Sorts', fontStyle: 'italic' }}>
                  
                    </GuestSpeechHeading>
                <GuestSpeechContent>
                  Had gone for conference cum pleasure trip. Good rooms, Service, Food is excellent, All
                  staff is smiling and always welcoming. Cleanliness is also good. Keep it up Hilltone.-
                  MR. NISCHITH M
                  </GuestSpeechContent>
              </Block>
            </q>
          </StyledQuote>
          <StyledQuote>
            <q style={{ fontSize: '3rem', fontWeight: 'bold' }}>

              <Block>
                <GuestSpeechHeading style={{ fontFamily: 'OFL-Sorts', fontStyle: 'italic' }}>
                  
                    </GuestSpeechHeading>
                <GuestSpeechContent>
                  Recommended hotel for family...there is not a single point on which I can complain.
                  Perfect hotel for family. Wonderful gardens, play area, kids park, pool, tasty &amp;
                  affordable food with good quantity. Nice staff. Last but not the least, folk music night
                  everyday by Live group.-MRS. RICHA BHARDWAJ
                  </GuestSpeechContent>
              </Block>
            </q>
          </StyledQuote>
          <StyledQuote>
            <q style={{ fontSize: '3rem', fontWeight: 'bold' }}>

              <Block>
                <GuestSpeechHeading style={{ fontFamily: 'OFL-Sorts', fontStyle: 'italic' }}>
                  
                    </GuestSpeechHeading>
                <GuestSpeechContent>
                  We stayed for 3 nights in Luxury Room with balcony including complimentary
                  Breakfast. Property is at nice location with peaceful environment. Food quality was
                  also very good and staff was very cooperative. Awesome stayed.- MR. CHIRAG  
                  </GuestSpeechContent>
              </Block>
            </q>
          </StyledQuote>
          <StyledQuote>
            <q style={{ fontSize: '3rem', fontWeight: 'bold' }}>

              <Block>
                <GuestSpeechHeading style={{ fontFamily: 'OFL-Sorts', fontStyle: 'italic' }}>
                  
                    </GuestSpeechHeading>
                <GuestSpeechContent>
                  I recently held two conferences at Hotel Hilltone. Over the past 14 years, I’ve hosted
                  conferences all around the country. Hotel Hilltone is an excellent choice for anybody
                  considering hosting their events there. –MR. AJAY NARNOLIA
                  </GuestSpeechContent>
              </Block>
            </q>
          </StyledQuote>
        </Carousel>
        <BtnContainer>
          <Btn onClick={e => {
            document.getElementsByClassName('control-prev')[0].click();
          }}>
            <Arrow />
          </Btn>
          <Btn onClick={e => {
            document.getElementsByClassName('control-next')[0].click();
          }}>
            <Arrow right={true} />
          </Btn>
        </BtnContainer>
      </React.Fragment>
    )
  }
}

export default Comments;

const StyledQuote = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 2rem;
`;

const GuestSpeechContent = styled.div`
  font-family: OFL Sorts Mill Goudy TT;
  font-style: italic;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.2;
  text-align: center;
  color: #FFFFFF;
`;

const GuestSpeechHeading = styled.div`
  font-family: OFL Sorts Mill Goudy TT;
  font-style: italic;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
  padding-bottom: 20px;
  text-align: center;
  color: #FFFFFF;
`;

const Block = styled.div`
  padding-bottom: 1.5rem;
  width: 70%;
  margin: 0 auto;
  @media (max-width: 600px) {
      width: 90%;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  @media only screen and (min-width: 750px) {
    margin: 0 10%;
  }
`;

const Btn = styled.button`
  border: none;
  width: 40px;
  height: 40px;
  background: rgba(126,118,71);
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;

const Arrow = styled.i`
  border: solid #fff;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: ${({right}) => right
    ? 'rotate(-45deg); margin-left: -2px;'
    : 'rotate(135deg); margin-left: 2px;'
  };
`;