import React from 'react';
import styled from 'styled-components';

import LandingContent from '../../components/LandingContent';
import Footer from '../../components/Footer';
import ContentRooms from './components/ContentRooms';
import theme from '../../utils/theme';

import rock from '../../images/Rooms/caveroom/1.jpg';
import deluxe from '../../images/Rooms/deluxeroom/1.jpg';
import executive from '../../images/Rooms/executivesuite/1.jpg';
import luxury from '../../images/Rooms/luxuryroom/1.jpg';
// import washRoom from '../../images/Rooms/villas/5.jpg';
import premier1 from '../../images/Rooms/premier.png';
import jacuzzi from '../../images/Rooms/villas/1.jpg';
import superior from '../../images/Rooms/superior-room/1.jpg';

const Rooms = (props) => {
  return (
    <Container>
      <LandingContent images={[ premier1, deluxe, luxury, rock, executive, jacuzzi, ]} />
      <ContentRooms {...props} />
      <Footer />
    </Container>
  )
}

export default Rooms;

const Container = styled.div`
  width: 100%;
  color: ${theme.primary};
`;
