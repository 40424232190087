import React from 'react';
import styled from 'styled-components';

import Content from './components/Content';

const Home = (props) => {
  return (
    <StyledContainerHome>
      <Content {...props} />
      {/* <HomePageBody {...props} /> */}
    </StyledContainerHome>
  );
}

export default Home;

const StyledContainerHome = styled.div`
  max-width: 100vw;
  height: 100vh;
`;