import React from 'react';

import GeneralSuite from '../../../components/GeneralSuite';
import img1 from '../../../images/Rooms/villas/1.jpg';
import img2 from '../../../images/Rooms/villas/2.jpg';
import img3 from '../../../images/Rooms/villas/3.jpg';
import img4 from '../../../images/Rooms/villas/4.jpg';
import img5 from '../../../images/Rooms/villas/5.jpg';

import FancyButton from '../../../components/FancyButton';

const Villa = (props) => {
   return (
      <GeneralSuite
         Heading={"Luxury Room with Private Jacuzzi"}
         Description={"Room Description"}
         width={"50px"}
         SupportImages={[img1,img5]}
         DescriptionContent={() => <React.Fragment>
            <div style={{fontSize: '1.4rem', lineHeight: '2rem'}}>
               Spread over 300 sqft, our spacious and tastefully designed Luxury Rooms
               provide ample opportunity for you to switch off and completely
               unwind.
            </div> <br />
            <div style={{fontSize: '1.4rem', lineHeight: '2rem'}}>
               Soak yourself in the private Jacuzzi adjoining your
               room, to make your stay an unforgettable experience.
            </div>
            <div
               style={{ display: 'flex', marginTop: '2rem' }}
            >
                    <a href= "https://bookings.hotelhilltone.com/?propertyId=8826">
              <FancyButton style={{margin: '1rem 0'}} text="Book a Stay" />
              </a>
            </div>
         </React.Fragment>}
         roomType={"villas"}
         backFlag={true}
         {...props}
      />
   );
}

export default Villa;