import React from 'react';

import LandingContent from '../../components/LandingContent';
import Footer from '../../components/Footer';
import Content from './Content';
import defaultSrc1 from '../../images/Meetings and event/header-event/1.jpg';
import defaultSrc2 from '../../images/Meetings and event/header-event/2.jpg';
import defaultSrc3 from '../../images/Meetings and event/header-event/3.jpg';
import defaultSrc4 from '../../images/Meetings and event/header-event/4.jpg';
import defaultSrc5 from '../../images/Meetings and event/header-event/5.jpg';
import defaultSrc6 from '../../images/Meetings and event/header-event/6.jpg';
import defaultSrc7 from '../../images/Meetings and event/header-event/7.jpg';
import defaultSrc8 from '../../images/Meetings and event/header-event/8.jpg';
import defaultSrc9 from '../../images/Meetings and event/header-event/9.jpg';
import defaultSrc10 from '../../images/Meetings and event/header-event/10.jpg';
import defaultSrc11 from '../../images/Meetings and event/header-event/11.jpg';
import defaultSrc12 from '../../images/Meetings and event/header-event/12.jpg';
import defaultSrc13 from '../../images/Meetings and event/header-event/13.jpg';
import defaultSrc14 from '../../images/Meetings and event/header-event/14.jpg';
import defaultSrc15 from '../../images/Meetings and event/header-event/15.jpg';


const Meetings = (props) => {
    return (
      <React.Fragment>
        <LandingContent images={[defaultSrc1, defaultSrc2, defaultSrc3, defaultSrc4, defaultSrc5, defaultSrc6, defaultSrc7, defaultSrc8, defaultSrc9, defaultSrc10, defaultSrc11, defaultSrc12, defaultSrc13, defaultSrc14, defaultSrc15]} />
        <Content />
        <Footer />
      </React.Fragment>
    );
}

export default Meetings;