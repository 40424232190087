import React from 'react';
import styled from 'styled-components';

import SocialIcons from '../SocialIcons/SocialIcons';

const google = require('../../images/Footer/google.png');
const trivago = require('../../images/Footer/trivago.png');
const makemytrip = require('../../images/Footer/makemytrip.png');
const agoda = require('../../images/Footer/agoda.png');
const cleartrip = require('../../images/Footer/cleartrip.png');
const holidayIQ = require('../../images/Footer/HolidayIQ.png');
const tripAdvisor = require('../../images/Footer/tripAdvisor.png');
const bookingDotCom = require('../../images/Footer/booking.png');
const goibibo = require('../../images/Footer/goibibo.png');
const yatra = require('../../images/Footer/yatra.png');
const back = require('../../images/about_main_img.png');
const trip = require('../../images/Footer/trip advisor.png');

const Footer = () => {
  return (
    <div>
      <Upper>
        <Container>
          <StyledContact>
            <OneThird>
              <Block>
                <Content style={{ fontWeight: 'bold' }}>HOTEL HILLTONE</Content>
                <Content>Main Road, </Content>
                <Content>Mount Abu - 307 501 </Content>
                <Content>Rajasthan, India</Content>
              </Block>
              <Block>
                <Content>Email: </Content>
                <Content>reservations@hotelhilltone.com</Content>
                <Content>gm@hotelhilltone.com</Content>
              </Block>
              <block>
              <StyledImage src={trip} />
              </block>
            </OneThird>
            <OneThird>
              <Block>
                <Content>Phone: +91 2974 238391 - 94 </Content>
                <Content>Fax: +91 2974 238395 </Content>
                <Content style={{display: 'flex'}}>
                  <div>Mobile:&nbsp;</div>
                  <div>+91 9414154888,<br />+91 9460654888</div>
                </Content>
              </Block>
            </OneThird>
            <OneThird style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <SocialIcons />
            </OneThird>
          </StyledContact>
        </Container>
      </Upper>
      <Partners>
        <StyledImage src={google} />
        <StyledImage src={trivago} />
        <StyledImage src={makemytrip} />
        <StyledImage src={agoda} />
        <StyledImage src={cleartrip} />
        <StyledImage src={holidayIQ} />
        <StyledImage src={tripAdvisor} />
        <StyledImage src={bookingDotCom} />
        <StyledImage src={goibibo} />
        <StyledImage src={yatra} />
      </Partners>
    </div>
  );
}

export default Footer;

const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
    @media (min-width: 768px) {
      width: 750px;
    }
    @media (min-width: 992px) {
      width: 970px;
    }
    @media (min-width: 1200px) {
      width: 1170px;
    }
`;

const StyledContact = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  @media only screen and (max-width: 1020px) {
    grid-template-columns: repeat(2, auto);
  }
  margin-bottom: 1rem;
  @media only screen and (max-width: 550px) {
    grid-template-columns: repeat(1, auto);
  }
`;

const OneThird = styled.div`
  padding: 0;
  margin: 1rem 0;
`;

const Content = styled.div`
  font-size: 1.3rem;
  line-height: 1.4;
`;

const Block = styled.div`
  padding: 1.5rem 0;
`

const Partners = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-row-gap: 2.5rem;
  justify-content: space-around;

  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;


  text-align: center;
  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(4, auto);
  }
  @media only screen and (max-width: 650px) {
    grid-template-columns: repeat(2, auto);
  }
`;

const StyledImage = styled.img`
  min-height: 80px;
  @media only screen and (max-width: 550px) {
    height: 25px;
  }
`;

const Upper = styled.div`
  color: #ffffff;
  padding: 5rem 0;
  opacity: .6;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient( rgba(0,0,0,.6),rgba(0,0,0,.6)), url(${back});  
`;